
import productObj from '../../utility/productClass'
import customerObj from '../../utility/customerClass'
import { useState, useEffect } from 'react';
import { Empty, notification, Popconfirm } from 'antd';
import {DeleteOutlined,} from '@ant-design/icons';
import { Link } from 'react-router-dom';
const Wishlist =()=>{
const [wishlistdata,setwishlist]=useState({list:[],url:""})

useEffect(()=>{
      async  function getdata(){
       const res= await customerObj.getWishlist();
       setwishlist(res);
    }
    getdata();
   

},[])

const deleteCartItem = async (item)=>{
  try{
    const res= await customerObj.deleteWishlist(wishlistdata.list[item].id);
    wishlistdata.list.splice(item, 1);
    setwishlist({...wishlistdata});
    notification.success({ key:'delete', message: 'Item has been  removed Successfully' })
  }catch(e){


  }
   
}

return (<>
  <div className="row">
                    <div className="col-md-12">
                        <div className="product-content-right">
                            <div className="woocommerce">
                                <form method="post" action="#">
                                    <table cellspacing="0" className="shop_table cart">
                                        <thead>
                                            <tr>
                                          
                                                <th className="product-thumbnail">Product Image</th>
                                                <th className="product-name">Product</th>
                                                <th className="product-price">Price</th>
                                                <th className="product-remove">&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                wishlistdata.list.map((item, itemkey) => {
                                                    return (
                                                        <tr className="cart_item">

                                                            <td className="product-thumbnail">
                                                                { productObj.productImage(item.product,wishlistdata.url)}


                                                            </td>

                                                            <td className="product-name">
                                                                {productObj.getProductName(item.product, true)}
                                                            </td>

                                                            <td className="product-price">
                                                                <span className="amount">{productObj.PriceDisplay(item.product)}</span>
                                                            </td>   
                                                            <td className="product-remove">
                                                                <Popconfirm
                                                                    title="Do you  want to Delete？"
                                                                    onConfirm={(e)=>deleteCartItem(itemkey)}

                                                                >
                                                                    <Link to="" ><DeleteOutlined /></Link>
                                                                </Popconfirm>
                                                            </td>                                                        
                                                        </tr>
                                                    )
                                                })
                                            }   
                                            { wishlistdata.list.length == 0 && <tr><td colSpan="4"><Empty></Empty></td></tr>}
                                                                                    
                                        </tbody>
                                    </table>
                                </form>


                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">

                      


                    </div>
                </div>
</>)

}
export default Wishlist;