import React, {useState, useEffect} from 'react'
import "bootstrap/dist/css/bootstrap.min.css"
import {Button,EditOutlined,Pagination} from 'antd';
import SeoObj from '../../../utility/adminClass'
import {Link,} from "react-router-dom";
  
export default function List(){
     const [seolist,setList]=useState({list:[],count:0}); 
     const [paging, setpage] = useState({ currentpage: 1, itemlimit: 20 });
     //const [filter, setFilter] = useState({  });  
     /*********Token send***********/  
     let token = sessionStorage.getItem('_adminitstore');
     /**********End**********/
   
     useEffect(()=>{          
          async  function  getSeoListData(){
               const data =await SeoObj.getSeoPagination({ page: paging.currentpage, limit: paging.itemlimit},{headers: {"Authorization" : `Bearer ${token}`} });
               console.log(data);
               setList({list:data.rows,count:data.count});
          }
          getSeoListData();
     },[paging])

     const handlePageChange = (page) => {
          setpage((pre) => {
              return { ...pre, currentpage: page }
          })
     } 
     
    
          return (
               <div>
                 
                     <table className="table table-light">
                         <thead>
                         <tr>
                              <th scope="col">Sr No</th>
                              <th scope="col">Page Name</th>
                              <th scope="col">Meta Title</th>
                              <th scope="col">Meta Keywords</th>
                              <th scope="col">Meta Description</th>
                              <th scope="col">Action</th>
                         </tr>
                         </thead>
                         <tbody>
                              {
                              seolist.list.length > 0 && seolist.list.map((seodata,sr) => {
                                   return(
                                   <tr>
                                        <td>{sr+1}</td>
                                        <td>{seodata.pageName}</td>
                                        <td>{seodata.metaTitle}</td>
                                        <td>{seodata.metaKeywords}</td>
                                        <td>{seodata.metaDescription}</td>
                                        <td><Button><Link to={'/admin/seoSettingEdit/'+seodata.id}>Edit</Link></Button></td>
                                        
                                   </tr>)
                                   
                              })}                         
                         </tbody>
                     </table>
                 <Pagination defaultPageSize={20} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`} defaultCurrent={paging.currentpage} total={seolist.count} onChange={handlePageChange} />
              
               </div>  
          )   
}
