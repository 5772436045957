import { Button, Form, Input, Switch } from 'antd';
import { useState, useEffect,useRef } from 'react';
import CustomerObj from '../../utility/customerClass';
import { useNavigate , Link} from 'react-router-dom';
import Config from '../../config/index';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import cartObj from '../../utility/cartClass'
const amount = "2";
const currency = "USD";
const style = {"layout":"vertical"};

const PAYPAL = (props) =>{
   const inputRef = useRef(null)
    const navigate= useNavigate()
  const order=props.order;
  //console.log('order satish');
  //console.log(order);

let [captchaurl,setCaptchaUrl]=useState("");
let [captchaNumber,setCaptchaNumber]=useState("");
let [cartTotal,setCartTotal]=useState(0);





useEffect(() => {
const total=  cartObj.cartTotal();
setCartTotal(total);
//console.log(Config.url);
let url = Config.url+'payment/'+order.id;
//console.log(url);
window.location.href = url;
}, [])




    return (<>
   {/* <div className="paypalbox" ref={inputRef}>
      <PayPalScriptProvider options={{ "client-id": "AX6ssF8XJAXdSJnHFH9mSzJ0Yj7N4-KK2z1qqVoI3o8oH8gDZJ37fGbortthQgZ4E135RH2g-Cvcb8oK" }}>
      <PayPalButtons
                style={style}
                disabled={false}
                forceReRender={[amount, currency, style]}
                fundingSource={undefined}
                createOrder={(data, actions) => {
                    return actions.order
                        .create({
                            purchase_units: [
                                {
                                    amount: {
                                        currency_code: currency,
                                        value: cartTotal,
                                    },
                                },
                            ],
                        })
                        .then((orderId) => {
                            // Your code here after create the order
                            return orderId;
                        });
                }}
                onApprove={function (data, actions) {
                    return actions.order.capture().then(function (details) {
                       // console.log(data);
                        //console.log(actions);
                        console.log(details);
                        props.updatepayment(details);
                        // Your code here after capture the order
                    });
                }}
            />
        </PayPalScriptProvider>
        </div>
            */}
            </>

    )

}
 export default PAYPAL;