//,DELETE_CART,UPDATE_CART,INCREASE_QUANTITY,DECREASE_QUANTITY,EMPTY_CART
import {ADD_CART,GET_CART,LOAD_CART,EMPTY_CART} from  '../action/cartAction';
import cartObj from '../../utility/cartClass'
const initialCart={}

function cartReducer(state=initialCart,action){
    switch (action.type){
        case GET_CART:
            return state;      
        case ADD_CART:
           return cartObj.AddtoCart(state,action);
        case LOAD_CART:
        return cartObj.loadCart(state,action);
        case EMPTY_CART:
            return {};
           
        default:
            return state;
        
    }



}
export default cartReducer;

