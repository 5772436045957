import axios from '../config/axios';
import { notification } from 'antd';

function getResponseHandler(response) {

    if (response.data.status === 1) {
        return response.data.data;
    } else {
        notification.error({ message: response.data.errormessage });
        return [];
    }

}
export const getProducts = async (data = {}) => {

    try {
        const rowData = await axios.post('/product/', data);
        const response = await rowData;
        return getResponseHandler(response);


    } catch (error) {
        console.error(error);
        notification.error({ message: error.message });

    }
}

export const getProductDetail = async (sku='') => {

    try {
        const rowData = await axios.post('/product/detail', {sku:sku});
        const response = await rowData;
        return getResponseHandler(response);


    } catch (error) {
        console.error(error);
        notification.error({ message: error.message });

    }
}


export const getRelatedProducts = async (id='',refId='') => {

    try {
        const rowData = await axios.post('/product/related', {productid:id,refId:refId,limit:25});
        const response = await rowData;
        return getResponseHandler(response);


    } catch (error) {
        console.error(error);
        notification.error({ message: error.message });

    }
}

export const getProductVariantService = async (id='') => {

    try {
        const rowData = await axios.post('/product/variant', {productId:id});
        return getResponseHandler(rowData);


    } catch (error) {
        console.error(error);
        notification.error({ message: error.message });

    }
}

export const callApi= async (url,data) => {
    //console.log(url+''+data);
    try {
        const rowData = await axios.post(url, data);
        return getResponseHandler(rowData);


    } catch (error) {
        console.error(error);
        notification.error({ message: error.message });

    }
}

