import React, {useState, useEffect} from 'react'
import "bootstrap/dist/css/bootstrap.min.css"
import {Button,Modal,Pagination,Popconfirm, notification} from 'antd';
import { ExclamationCircleOutlined,DeleteOutlined } from '@ant-design/icons';
import ShippingObj from '../../../utility/adminClass'
import {Link,} from "react-router-dom";
  
export default function List(){
     const [shippinglist,setList]=useState({list:[],count:0}); 
     const [paging, setpage] = useState({ currentpage: 1, itemlimit: 20 });
     /*********Token send***********/  
     let token = sessionStorage.getItem('_adminitstore');
     /**********End**********/
     async  function  getShippingListData(){
          const data =await ShippingObj.getShippingRules({ page: paging.currentpage, limit: paging.itemlimit},{headers: {"Authorization" : `Bearer ${token}`} });
          console.log(data);
          setList({list:data.rows,count:data.count});
     }
     useEffect(()=>{          
         
          getShippingListData();
     },[paging])

     const handlePageChange = (page) => {
          setpage((pre) => {
              return { ...pre, currentpage: page }
          })
     } 
     const { confirm } = Modal;
     const showConfirm = async (id) => {
          confirm({
          title: 'Do you Want to delete these Shipping Rules?',
          icon: <ExclamationCircleOutlined />,
          //content: 'Some descriptions',
          async onOk() {
               const deleteData = await ShippingObj.deleteShippingRules({id:id});
               console.log(deleteData);
               if(deleteData?.data?.length > 0){ 
                    notification.success({message:'Shipping Rules data has been deleted successfullly'});                   
               }
               getShippingListData();
          },
          onCancel() {
               console.log('Cancel');
          },
     });
     };
     
    
          return (
               <div>
                 <Button type="primary" style={{ float: "right", marginBottom: 6}}><Link to={'/admin/shippingRulesAdd/'}>Add</Link></Button><br />
                                        
                     <table className="table table-light">
                         <thead>
                         <tr>
                              <th scope="col">Sr No</th>
                              <th scope="col">Country Name</th>
                              <th scope="col">State Name</th>
                              <th scope="col">Type</th>
                              <th scope="col">Rate</th>
                              <th scope="col">Action</th>
                         </tr>
                         </thead>
                         <tbody>
                              {
                              shippinglist.list.length > 0 && shippinglist.list.map((shipingdata,sr) => {
                                   return(
                                   <tr>
                                        <td>{sr+1}</td>
                                        <td>{shipingdata.country.name}</td>
                                        <td>{shipingdata.state.name}</td>
                                        <td>{shipingdata.type}</td>
                                        <td>{shipingdata.rate}</td>
                                        <td><Link to={'/admin/shippingRulesEdit/'+shipingdata.id}><i class="fa fa-edit"></i></Link>&nbsp;
                                       <DeleteOutlined onClick={e => showConfirm(shipingdata.id)} />
                                        </td>
                                   </tr>)
                                   
                              })}                         
                         </tbody>
                     </table>
                 <Pagination defaultPageSize={20} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`} defaultCurrent={paging.currentpage} total={shippinglist.count} onChange={handlePageChange} />
              
               </div>  
          )   
}
