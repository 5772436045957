import { Button, Form, Input, Switch, Result } from 'antd';
import authObj from '../../utility/authclass'
import { useDispatch } from 'react-redux';
import { int } from '../../redux/action/userAction';
import { useNavigate , Link} from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/index';
const About = (props) => {
    
    const componentSize = 'large';
    const loginkey = 'login';
    const page_title = {
        fontSize: "24px",
        lineHeight: "30px",
        fontWeight: "700",
        boxShadow: "0 -1px #eee inset",
        textTransform: "capitalize"
    }
    const page_title_wrapper = {
        display: "inline-block",
        padding: "5px 0 7px",
        borderBottom: "3px solid #01adeb"
    }
    const container_top = {       
        fontFamily: "Arial",
      
      };
      const about_us = {
        padding: "5px",
        fontFamily: "Arial",
        margin:"5px"
      }

    return (<>
        <Header></Header>
        <div className="container" style={container_top}>
     
        <div class="page-title-wrapper"><h1 class="page-title" style={page_title}><span class="base" data-ui-id="page-title-wrapper" style={page_title_wrapper}>About ITSUPERSTORE</span></h1></div>
       
        <p>Welcome to ITSUPERSTORE, the world’s leading independent supplier of HP servers and Server options. Complementing the conventional Distribution model for a decade, ITSUPERSTORE offers resellers in the IT industry an alternative way to purchase HP Servers and server options. Our recipe for success lies in providing our customers with highly experienced and responsive salespeople, a large selection of in – stock available inventory, Extensive configuration services performed by certified technicians, and truly competitive pricing that allows our partners to simply win more opportunities.</p>
        <p>Over the years, ITSUPERSTORE has developed a massive Global sourcing network consisting of manufacturers and top tier wholesalers that when combined, provide for substantial buying power. What does this mean for our customers? It means unprecedented cost savings without the hassle of filling out complicated and often intrusive paperwork. Although ITSUPERSTORE primarily focuses on brand new, current HP servers and server options, we also provide further solutions for our customers by stocking end of life products, HP Renew, HP Factory sealed spares, Refurbished, and even used parts. Any products that are designated as non-factory sealed parts are always tested thoroughly by our highly trained professional technicians ensuring that our customers receive nothing but the highest quality and perfectly operating parts.</p>
        <p>ITSUPERSTORE  provides it’s customers with free configuration services in our 60,000 square foot integration facility. Known for our quick turnaround time, our highly trained HP certified technicians are proficient in configuring Blades, Storage Solutions, Server installation, Imaging, installation of Operating systems, or just simple hardware assembly. With the ability to configure over 100 servers a day, our integration facility is one of the most efficient in the industry.</p>
        <p>Since 2003 ITSUPERSTORE Technology has partnered with over 5,000 industry resellers and has sold over $1B of IT equipment throughout the world. Through cost effective solutions, quick and up-front competitive pricing, state of the art configuration facility and integration services – ITSUPERSTORE provides a clear and attractive solution to resellers purchasing HP servers and server options.</p>
        <h4 style={about_us}>Company Overview</h4>
        <p>Founded in 2003, ITSUPERSTORE is a Hewlett-Packard Independent Distributor, Warranty, Service and Repair Center. We specialize in server and components, however, we also extend our repair center to personal desktops, laptops, and printers. We have an experienced and dedicated sales team to assist you with all your HP and Compaq server and server option needs. ITSUPERSTORE strives to be the best in customer satisfaction. We want every phone call and transaction to be a pleasant experience so that you keep coming back for more.</p>
        <p>Offering competitive pricing for retail, used, and refurbished products, we live up to our motto – “We stock everything we sell!” If we do not have it in stock, we will find it for you! Count on ITSUPERSTORE to efficiently deliver fast and friendly products and services. Globalization is key. Whether you are down the street or across the sea, no distance is too far for us to ship. Call or email us today about our discounted international shipping rates.</p>
        <p>Our extensive network of manufacturers and distributors ensures that we get the best deal on prices, which are ultimately passed on to your customers. To ensure timely delivery we have an efficient logistics system in place. Our mantra has always been to ‘stock what we sell’, that includes both new and end-of-life products.</p></div>
         <Footer></Footer>
    </>);
}
export default About