import { Button, Form, Input, notification } from 'antd';
import customerObj from '../../utility/customerClass'
import Header from '../Header/Header';
import Footer from '../Footer/index';
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
    const navigate=useNavigate();
    const onFinish = async (values) => {
        const data = await customerObj.forgotPassword(values.email);
        console.log('Forgot Passwrord');
        console.log(data);
        console.log(Object.keys(data).length);
        if(Object.keys(data).length>0){
            console.log('Message');
            notification.success({message:'Reset link shared on your Email'});
            navigate("/login");
        } 
    };
    return (<>
      <Header></Header>
        <div className="single-product-area">
            <div className="zigzag-bottom"></div>

            <div className="container">
                <div className="row align_center">
             
                <div className="ragistraiton_box">
                <div className="image_box">
                <img src="assets/img/User-Registration.png" alt="ragistraiton" />
                    </div>  
                <div className="form_box">
                  
                <h3>Forgot Password</h3>
                <div className="loginBox">
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 20 }}
              
                onFinish={onFinish}
                autoComplete="off"
            >

                
                <Form.Item
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Email!',
                        },
                        {type: 'email',
                        message: 'Please input Valid Email!',}
                    ]}
                >
                    { /*prefix={<UserOutlined className="site-form-item-icon" /> */}
                    <Input className={"form-control"} placeholder="Email" />
                </Form.Item>
                <div className="row">
                    <div className="col-3">
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            Submit
                        </Button>
                    </div>
                   
                </div>
            </Form>
        </div>
       
        </div>
                </div>



                </div>
            </div>
            <div className="container">
                <div className="row">
                <div className="col-md-6">
                </div>
                <div className="col-md-6">
         
                </div>
                </div>
            </div>
        </div>
        
        <Footer></Footer>
    </>)

}
export default ForgotPassword;