import { useState, useEffect } from "react";
import { Form, Input, Button, Row, Col, Select, Spin,notification } from 'antd';
import ShippingObj from '../../../utility/adminClass'
import commonObj from '../../../utility/commonclass'
import { useParams,useNavigate } from 'react-router-dom';
const { Option } = Select;
const Add = () => {
    const navigate=useNavigate();
    const [countryList, setcountry] = useState({ list: [], isload: true });
    const [stateList, setstate] = useState({ list: [], isload: true });
    const [form] = Form.useForm();
    useEffect(() => {
        async function getData() {
            const countrydata = await commonObj.getCountryList();           
            setcountry({ list: countrydata.list, isload: true });
        }
        getData();


    }, [])

    const onFinish = async (values) => {
        //console.log(values);
        try {
            let Add = await ShippingObj.AddShippingRules(values); 
            console.log('Add');
            console.log(Add);            
            if(Add?.data?.length > 0){    
                notification.success({key:'save',message:'Shipping Rules has been saved successfully'});
               setTimeout(() => {
                navigate('/admin/shippingRules')
            }, 1000)
            }            
        } catch (e) {
            notification.error({key:'error',message:'Failed something went wrong'})
        }

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const selectCountry = async (val, option) => {
        setstate({ list: [], isload: false });
        const statedata = await commonObj.getStateList(val);
        setstate({ list: statedata.list, isload: true });
        console.log(statedata);
    }

    const [ rateType, setRateType ] = useState("");
    return (<>
        <div className="address-box">

            {<Form
                name="basic"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 16 }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                form={form}
            >
               <Form.Item
                    name="country"
                    label="Country"
                    rules={[
                        {
                            required: true,
                            message: 'Please Select Country',
                        },
                    ]}
                >

                    <Select
                        showSearch
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                            return option.children.toLowerCase().includes(input.toLowerCase())
                        }}
                        filterSort={(optionA, optionB) =>

                            optionA.children.localeCompare(optionB.children)
                        }
                        onSelect={selectCountry}
                    >
                        {countryList.list.map((countryItem, citemKey) => {
                            return <Option value={countryItem.id} key={citemKey}>{countryItem.name}</Option>

                        })}

                    </Select>
                </Form.Item>


                <Form.Item  label="State"
                    rules={[
                        {
                            required: true,
                            message: 'Please Select State',
                        },
                    ]}
                    name="State">
                    <Select
                     showSearch
                     mode="multiple"
                     placeholder="Search to Select"
                     optionFilterProp="children"
                     filterOption={(input, option) => {
                         return option.children.toLowerCase().includes(input.toLowerCase())
                     }}
                     filterSort={(optionA, optionB) =>

                         optionA.children.localeCompare(optionB.children)
                     }
                        onChange={value => {
                        //console.log(value);
                    }}
                
                    >
                        {stateList.list?.map(value => {
                        return (
                            <Option key={value.id} value={value.id}>
                            {value.name}
                            </Option>
                        );
                        })}
                    </Select>
                    </Form.Item>
               
                <Form.Item
                    name="type"
                    label="Type"
                    rules={[
                        {
                            required: true,
                            message: 'Please Select Type',
                        },
                    ]}
                >

                    <Select
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        onChange={value => setRateType(value)}                       
                    >
                    <Option value="Fedex-Api" key="Fedex-Api">Fedex-Api</Option>
                    <Option value="Exclude" key="Exclude">Exclude</Option>        
                    <Option value="Custom" key="Custom">Custom</Option>                       
                    </Select>
                 </Form.Item>


                {rateType === 'Custom' && (
                <Form.Item
                    name="rate"
                    label="Rate"
                >
                    <Input className={"form-control"} placeholder="Rate" />
                </Form.Item>
                 )}

                <Form.Item
                        wrapperCol={{
                        offset: 8,
                        span: 16,
                        }}
                >
                    <Button type="primary" htmlType="submit" style={{width:"15%"}}>
                    Submit
                    </Button>
                </Form.Item>

            </Form>}

        </div>


    </>)

}
export default Add;