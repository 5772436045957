import {
 
  PushpinOutlined,
  ShoppingOutlined,
  UserOutlined,
  HeartOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import { useNavigate } from "react-router-dom";

const MenuSide = () => {

  const navigate = useNavigate();
  const onClick = (e) => {
    if (e.key === 'profile') {
      navigate('/customer')
    } else if (e.key === 'address') {
      navigate('/customer/address')
    } else if (e.key === 'myorder') {
      navigate('/customer/myorder')
    } else if (e.key === 'wishlist') {
      navigate('/customer/wishlist')
    } else if (e.key === 'password') {
      navigate('/customer/changepassword')
    } else {
      navigate('/customer')
    }
  };
  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    };
  }
  const items = [
    getItem('Profile', 'profile', <UserOutlined />),
    getItem('Address', 'address', <PushpinOutlined />),
    getItem('My Orders', 'myorder', <ShoppingOutlined />),
    getItem('Wishlist', 'wishlist', <HeartOutlined />),
    getItem('Change Password', 'password', <SettingOutlined />),

  ];

  return (<> <Menu className='admin_left_menu'
  
    onClick={onClick}
    defaultSelectedKeys={['1']}
    defaultOpenKeys={['sub1']}
    mode='inline'
    theme='light'
    items={items}
  /></>)
}
export default MenuSide;