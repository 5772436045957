
import cartObj from '../../utility/cartClass'
import {Link} from 'react-router-dom'
import { useEffect, useState } from 'react';
import { Card, notification, Collapse, Empty, Radio,Space,Spin,Select } from 'antd';
import PAYPAL from '../Payment/PAYPAL'
import Payment from '../Payment/index';
import common from '../../services/common.service';
import commonobj from '../../utility/commonclass';
const { Option } = Select;
const Summary = (props) => {
    const cartData = cartObj.cartData;
    console.log(cartData);
    const address=props.address;
    const selectPayment=props.selectPayment;   
    const [ismethodshow,setismethodshow] =useState(false);
    const [isfreeShipping,setisfreeShipping] =useState(false);
    const [shippingdetail,setshippingdetail] =useState({data:{},loader:true});
    const [shippingMethod,setshippingMethod] =useState([]);
    const [selectedShippingMethod,setselectedShippingMethod] =useState("");

    const [enablebtn,setenablebtn] =useState(false);
    //cartData.address=address;

    useEffect(()=>{        

        checkforfreeshiping();
       // getFedexMethod();

    },[props.address]);

    async function checkforfreeshiping(){
        setismethodshow(false);

        if(Object.keys(address).length > 0) {
            
            if(address?.countryId != 1){
                getFedexMethod(1);

            }else{
               let checkfree= await cartObj.checkforfreeshiping(props.address);
                if(checkfree==true){
                    getFedexMethod(0);
                }else{
                    getFedexMethod(1);

                }
                

            }
/*
            const freeShipping= await cartObj.checkforfreeshiping(address);
           //const freeShipping= false;
           setisfreeShipping(freeShipping);
            if(freeShipping==false){
                setenablebtn(false);
                setshippingdetail((pre)=>({...pre,loader:false}));
                setismethodshow(true);
               
               // const Shippingmethod= await commonobj.getFedexMethod();
                //console.log("Shippingmethod");
                //console.log(Shippingmethod);
            }else{
                setshippingdetail((pre)=>({...pre,loader:false}));
                setenablebtn(true);

            }*/


        }
    }

    async function getFedexMethod(type){
        const data= await common.getFedexMethod({type:type});
        setshippingMethod(data);
        setismethodshow(true);
    }

    async function getData(method){
        const Shipping= await cartObj.calculateShiping(address,method);
        console.log("chhhhcalculation");
        console.log(Shipping);
        
        if(Shipping !== false && Shipping?.status !="0"){
          setenablebtn(true);
          setshippingdetail({data:Shipping,loader:false});
        }else{
                if(Shipping == false){
                    notification.error({ key: 'shipping', message: "Please Select Address" });
                   
                }else {
                    notification.error({ key: 'shipping', message: Shipping?.message });   
                }

        }
      
    }

    const selectShippingMethod = async (val, option) =>{
        console.log(val);
        console.log(option);
        console.log(shippingMethod[val]);
        setisfreeShipping(false);
        setenablebtn(false);

        if(commonobj.isEmpty(shippingMethod[val]?.service_value)==false){
            if(shippingMethod[val]?.isFreeShipping==1 ){
                setshippingdetail((pre)=>({...pre,loader:false}));
                setisfreeShipping(true);
                setenablebtn(true);
            }else{
                setselectedShippingMethod(shippingMethod[val]?.service_value);
                setshippingdetail((pre)=>({...pre,loader:true}));
                if(commonobj.isEmpty(address)==false && Object.keys(address).length > 0){
                    getData(shippingMethod[val]?.service_value);
                }
            }
            
            
        }
        
        


    }


    return (<>
        <div className="cart_totals ">

            <table cellspacing="0">
                <thead>
                    <tr>
                        <th colspan="2"><h3>Cart Totals</h3></th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="cart-subtotal">
                        <th>Cart Subtotal</th>
                        <td><span className="amount">{cartObj.cartGrossTotalDisplay()}</span></td>
                    </tr>

                  {address?.pincode=="32746" &&  <tr className="cart-subtotal">
                        <th>Tax 7%</th>
                        <td><span className="amount">{cartObj.cartTaxDisplay()}</span></td>
                    </tr>
                    }

                    {ismethodshow==true && <tr className="shipping">
                
                        <th colSpan="2">
                            <Select
                            placeholder="Shipping Method"
                            onSelect={selectShippingMethod}
                            style={{width:"100%"}}
                            disabled={props.paymentMode==0?false:true}
                            >
 {shippingMethod.map((item, key) => {
                            return <Option value={item.id} key={key} className={item?.isFreeShipping==1?"boldoption":""}>

                            {item?.isFreeShipping==1 && item.service_type+"(Free Shipping)" }
                             {item?.isFreeShipping==0 && item.service_type }
                            


                            </Option>

                        })}

                            </Select>
                        </th>
                    </tr>
                    }

                    <tr className="shipping">
                        <th>Shipping and Handling</th>
                        <td>
                        {shippingdetail.loader==true  && <Spin></Spin>}
                        {shippingdetail.loader==false && isfreeShipping==true && "Free Shipping"}                       
                        {shippingdetail.loader==false  && isfreeShipping==false && selectedShippingMethod!="" && cartObj.shippingTotalDisplay()}</td>
                    </tr>

                    <tr className="order-total">
                        <th>Order Total</th>
    <td><strong><span className="amount"> {cartObj.cartTotalDisplay()}</span></strong> </td>
                    </tr>
        {
            <tr>
                <Payment orderFullDetal={props.orderFullDetal} address={props?.address}></Payment>
            </tr>
        }
                   {props.paymentMode==0 && <tr >
                        <th colspan="2">
                            
                        {enablebtn==true  &&  <Link to="" onClick={()=>props.makepayment()}  className='btn'>Continue</Link>}
                       
                        {enablebtn==false &&  <Link to="" style={{opacity:"0.3"}} disabled className='btn disable'>Continue</Link>}
                        </th>

                    </tr>}
                </tbody>
            </table>

        </div>
    </>)

}

export default Summary;