import Header from '../Header/Header';
import Footer from '../Footer/index';

import CartButton from './CartButton';
import { getProducts } from '../../services/product.service';
import customerObj from '../../utility/customerClass'
import { Skeleton, Pagination, Empty, notification } from 'antd';
import cartobj from '../../utility/cartClass';
import React, { useState, useEffect,memo } from 'react';
import productObj from '../../utility/productClass'
import commonObj from '../../utility/commonclass'
import authObj from '../../utility/authclass';
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import FilterComponent from './Filter';
import WishList from './Wishlist';
import NotifyButton from './NotifyButton';

const ProductList = function () {

    const [searchParams, setSearchParams] = useSearchParams();
    const [filtersort, selectedsort] = useState({ orderby: 'productName', type: 'ASC' });
    const [productListData, setProductList] = useState({ totalrecord: 0, list: [], url: "" });
    const [paging, setpage] = useState({ currentpage: 1, itemlimit: 12 });
    const [filter, setFilter] = useState({ searchkey: searchParams.get('key'), categories: [], price: [],manufacturer:[],condition:[] });
    const [componentLoader, setcomponentLoader] = useState(0);
    const [chatWidth, setChatWidth] = useState(undefined);
    const [sidebarTop, setSidebarTop] = useState(undefined);
    useEffect(() => {
        const chatEl = document.querySelector('.pro_sidebar').getBoundingClientRect();
        setChatWidth(chatEl.width);
        setSidebarTop(chatEl.top);
      }, []);
      useEffect(() => {
        if (!sidebarTop) return;
    
        window.addEventListener('scroll', isSticky);
        return () => {
          window.removeEventListener('scroll', isSticky);
        };
      }, [sidebarTop]);
      const isSticky = (e) => {
        const chatEl = document.querySelector('.pro_sidebar');
        const scrollTop = window.scrollY;
        if (scrollTop >= sidebarTop - 10) {
          chatEl.classList.add('is-sticky');
        } else {
          chatEl.classList.remove('is-sticky');
        }
      }; 
    let navigate = useNavigate();
    const QueryCategory = searchParams.get('category');
    const searchkey = searchParams.get('key');
    console.log(searchkey);
    useEffect(() => {

        if (searchParams.get('category')) {

            const qCat = parseInt(searchParams.get('category'));
            console.log(`query cat ${qCat}`)

            if (qCat > 0) {
                const tmp = [];
                tmp.push(qCat);
                let unique = [...new Set(tmp)];
                setFilter((pre) => ({ ...pre, categories: unique }));
            }

        }

    }, [QueryCategory])



    useEffect(() => {
        window.scrollTo(0, 0);
        setcomponentLoader(0);
        setProductList({ totalrecord: 0, list: [], url: "" });
        getProducts({ page: paging.currentpage, limit: paging.itemlimit, filter: { ...filter, searchkey: searchkey }, order: filtersort }).then(list => {
            setcomponentLoader(1);
            //console.log('satish');
            setProductList(() => {
                return { totalrecord: list.productdata.count, list: list.productdata.rows, url: list.url };
            })
        });

    }, [paging, searchkey, filter?.categories.length, QueryCategory, filter?.price?.min, filter?.price?.max, filtersort,filter?.condition?.length,filter?.manufacturer?.length]);

    const handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setFilter((pre) => {
            console.log(pre);
            let newobj = {};
            newobj[name] = value;
            return { ...pre, ...newobj }

        })
    }


    const handlePageChange = (page) => {

        setpage((pre) => {
            return { ...pre, currentpage: page }
        })
    }

    const FilterProduct = (filterArg) => {
        console.log('Call Filter Box');
        console.log(filterArg);
        setFilter((pre) => ({ ...pre, ...filterArg }));


    }

    const onChangeSort = (e) => {
        var index = e.target.selectedIndex;
        var optionElement = e.target.childNodes[index]
        var option = optionElement.getAttribute('data-attr');
        //console.log(option);
        //console.log(e.target.value);
        selectedsort({ orderby: e.target.value, type: option })
    };

    const handelshowsizechnage=(current,size)=>{
        console.log("current",current);
        console.log("size",size);
        setpage((pre)=>({...pre,itemlimit:size}));
    }


    return (
        <>

            <Header searchkey={filter.searchkey}></Header>
            <section className="shop-details pt-40 pb-40">

                <div className="container">
                    <div className="row">
                        <div className=" col-md-3">
                        <div className='pro_sidebar'style={{ width: chatWidth }}>
                            <FilterComponent FilterProduct={FilterProduct} ></FilterComponent>
                        </div>
                        </div>
                        <div className="col-md-9">
                            <div className="shop-top-area mb-20">
                                <div className="row">
                                    <div className="col-xxl-4 col-xl-2 col-md-3 col-sm-3">
                                        <div className="shop-top-left">

                                        </div>
                                    </div>
                                    <div className="col-xxl-4 col-xl-6 col-md-6 col-sm-6">

                                    </div>
                                    <div className="col-xl-4 col-xl-4 col-md-3 col-sm-3">
                                        <div className="text-sm-end">
                                            <div className="select-default">
                                                <select name="short" id="short" className="shorting-select form-select" onChange={(e) => onChangeSort(e)}>
                                                    <option data-attr="">Default sorting</option>
                                                    <option data-attr="ASC" value="productPrice">Price low to high</option>
                                                    <option data-attr="DESC" value="productPrice">Price high to low</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="shop-main-area mb-40">
                                <div className="tab-content" id="nav-tabContent">
                                    <div className="tab-pane fade active show" id="tab1">
                                        <div className="row">

                                            {

                                                productListData.list.map((item, itemkey) => {

                                                    //const slow = [1,2,3];
                                                    /*const wishdExist = wishlist.find(element => {
                                                         if (element == item.id) {
                                                             return true;
                                                         }else{             
                                                             return false;
                                                         }
                                                     });*/
                                                    return (<div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6" key={item.id} title={item.productName}>
                                                        <div className="product__item mb-20">
                                                            <div className="product__thumb w-img fix">
                                                                <Link to={`/product/` + item.productSku}>
                                                                    {
                                                                        productObj.productImage(item, productListData.url)
                                                                    }
                                                                </Link>
                                                                    <div className="product__action transition-3">
                                                                        <ul>
                                                                            <li>
                                                                                <WishList id={item.id} />
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    {/*item.productImage && <img src={productListData.url+item.productImage} alt="" />*/}
                                                                
                                                            </div>
                                                            <div className="product__content">
                                                                <div className="product__tag product__tag-4">
                                                                    <span className="sku-box"> SKU : <Link to={`/product/` + item.productSku}>{productObj.displaySKU(item)}</Link></span>
                                                                </div>
                                                                {cartobj.displayCondition(item)}
                                                                
                                                               
                                                                <h3 className="product__title" title={item.productName}>
                                                                    <Link to={`/product/` + item.productSku}>{item.productName}</Link>
                                                                </h3>
                                                               {/* <div className="product__price product__price-4 mb-10">
                                                                {productObj.ProductPrice(item) > 0 && <div className="price-box">
                                                                    <span className="price"><ins>{productObj.PriceDisplay(item)}</ins></span>
                                                                    {commonObj.isEmpty(cartobj.ProductCrossPrice(item))==false && <del>{cartobj.CrossPriceDisplay(item)}</del>}
                                                                     </div>
                                                
                                                }

                                                                    
                                                                    {cartobj.displayQuantity(item)}
                                                                   
                                            </div>*/}
                                            {productObj.displayPriceQuantity(item)}

                                                                <div className="product__select-button">
                                                                    {item?.productPrice > 0 && item?.productQuantity > 0 && <CartButton product={{ ...item, productImage: ((item.productImage) ? productListData.url + item.productImage : "") }} classes="select-btn w-100"></CartButton>}
                                                                    {(item.productPrice == 0 || item?.productQuantity==0)  && <><NotifyButton classes="select-btn w-100" item={item}></NotifyButton></>}

                                                                </div>

                                                                {

                                                                }

                                                            </div>
                                                        </div>

                                                    </div>)

                                                })

                                            }

                                            {productListData.list.length == 0 && componentLoader != 0 && <Empty />}

                                            {componentLoader == 0 && <Skeleton active={true} />}



                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="product-pagination text-center">
                                <nav>
                                   {productListData?.totalrecord > 0 && <Pagination defaultPageSize={paging?.itemlimit} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`} defaultCurrent={paging.currentpage} total={productListData.totalrecord} onChange={handlePageChange} pageSizeOptions={[12,24,48,100,200]} onShowSizeChange={handelshowsizechnage} />}
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <Footer></Footer>
        </>
    )

}
export default memo(ProductList)