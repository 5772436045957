import Header from '../Header/Header';
import Footer from '../Footer/index';
import OwlCarousel from 'react-owl-carousel';
import productobj from '../../utility/productClass';
import cartobj from '../../utility/cartClass';
import commonobj from '../../utility/commonclass';
import MyImage from '../Common/MyImage';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Skeleton } from 'antd';
import CartButton from './CartButton';
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getProductDetail, getRelatedProducts, getProductVariant } from '../../services/product.service';
import {    NotificationOutlined,  } from '@ant-design/icons';
import NotifyButton from './NotifyButton';
import PageNotFound from "../404Page/PageNotFound"

const options = {
    margin: 10,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: false,
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 3,
        },
        1000: {
            items: 4,

        },
        1366: {
            items: 5,

        }
    },
};
const Product = function () {
    const params = useParams()
    console.log(params)
    const sku = params.sku;
    const [is404,setpage]=useState(false)
    const [product, setProduct] = useState({ info: {}, media: [], url: "", galleryUrl: "" });
    const [productMedia, setproductMedia] = useState([]);
    const [bigMediaIndex, setbigMediaIndex] = useState(0);
    const [relatedproductListData, setRelatedProduct] = useState({ totalrecord: 0, list: [], url: "" });
   const [componentLoader, setcomponentLoader] = useState(0);

    useEffect(() => {
        setcomponentLoader(0);
        async function fetchData() {
            try {
                const data = await getProductDetail(sku);
                setcomponentLoader(1);
                if(data?.product==null){
                    setpage(true);
                    return false;
                }

                if (data.product.isVariant === 1) {
                    const variantData = await productobj.getProductVariant(data.product.id);

                    const productdata = { ...data.product };
                    productdata.variantData = variantData.variants;
                    setProduct((pre) => {
                        return { ...pre, info: productdata, url: data.url, galleryUrl: data.galleryUrl }

                    });

                } else {



                    setProduct((pre) => {
                        return { ...pre, info: data.product, url: data.url, galleryUrl: data.galleryUrl }

                    });

                    let media = [];
                    if (data?.product?.productImage) {
                        media.push(data.url + data?.product?.productImage);
                    } else {
                        media.push(data.url + 'product.png');
                    }
                    console.log('Product Media');
                    console.log(commonobj.isEmpty(product?.info?.productMedia));

                    if (commonobj.isEmpty(data?.product?.productMedia) == false) {
                        data?.product?.productMedia.map((item) => {
                            media.push(data.galleryUrl + item.mediaName);

                        });
                    }

                    console.log(media);
                    setproductMedia(media);


                }





                const relateddata = await getRelatedProducts(data.product.id, data.product.refId);
                if (commonobj.isEmpty(relateddata) == false) {
                    console.log('related data');
                    console.log(relateddata);
                    setRelatedProduct((pre) => {
                        return { ...pre, totalrecord: relateddata?.productdata?.length, list: relateddata?.productdata, url: relateddata?.url };
                    })

                }




            } catch (e) {
                console.log(e.message)
                return false;
            }
        }
       
        fetchData();
        window.scrollTo(0, 0);
    }, [sku]);

    const loadVarintData = (selectedvarint) => {
        console.log(selectedvarint);
        const attribute = Object.keys(selectedvarint).sort(function (a, b) { return a - b });

        const key = [];
        for (const element of attribute) {
            key.push(selectedvarint[element]);
        }
        const newprice = product.info.variantData.varintKey[key.join('-')]['price'];
        //console.log();
        setProduct((pre) => {

            return { ...pre, info: { ...pre.info, productVariantPrice: newprice, selectVarintKey: key.join('-') } };
        })
    }





    return (<>
        <Header></Header>
        {is404==true && <PageNotFound isHide={true}></PageNotFound>}
        {is404==false && <div className="single-product-area">
            <div className="zigzag-bottom"></div>
            <div className="container">
                <div className="row">

                    <div className="col-md-12">
                        <div className="product-content-right">
                            <div className="product-breadcroumb">
                                <a >Home</a>
                                {
                                    product.info.productName && <><Link to={'/productcategory/?category='+product?.info?.productCategory}>{product.info && product.info.category && product.info.category.categoryName}</Link>
                                        <a >{product.info && product.info.productName}</a></>
                                }
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="product-images">
                                        
                                        <div className="product-main-img">
                                           {componentLoader==0 && <Skeleton.Image active={true} />} 

                                            {componentLoader==1  && productobj.ShowImage(bigMediaIndex, productMedia)
                                            }
                                            
                                        </div>
                                        <div className="product-gallery">
                                      
                                            {componentLoader==1  &&
                                                productMedia.map((item, key) => {
                                                    return <span className="gallery_thumbnail_box" key={key} onClick={(e) => { setbigMediaIndex(key) }}><MyImage alt={"Image"} helght={100} width={100} caption={"Image"} src={item}  ></MyImage></span>
                                                })
                                            }
                                             
                                        </div>

                                    </div>
                                </div>

                                <div className="col-sm-6">

                                    {componentLoader==1  &&
                                        product.info.productName && <div className="product-inner">
                                            <h2 className="product-name">{productobj.getProductName(product.info)}</h2>
                                            <div className="sku-box lh-10"><small className="text-capitalize"><strong>Sku</strong> <a>{productobj.displaySKU(product.info)}</a> </small></div>
                                          {cartobj.getCondition(product.info) !='' && <div className="condition-box lh-10"><small className="text-uppercase"><strong>Condition  </strong>  {cartobj.getCondition(product.info)}</small></div>}  
                                           
                                            <small class="detail-qty lh-10">{cartobj.displayQuantity(product?.info)}</small>

                                            {product?.info?.productPrice > 0 && <div className="product-inner-price lh-10">

                                                

                                                {commonobj.isEmpty(productobj.ProductCrossPrice(product.info)) == false && <del>{productobj.CrossPriceDisplay(product.info)}</del>}
                                                <ins>{productobj.PriceDisplay(product.info)}</ins>


                                            </div>
                                            }

                                            <form action="" className="cart">
                                                {product?.info?.productPrice > 0 && product?.info?.productQuantity > 0 && 
                                                    <CartButton loadVarintData={loadVarintData} isqty={true} product={{ ...product?.info, productImage: ((product.info.productImage) ? product.url + product.info.productImage : "") }}></CartButton>
                                                }
                                                {(product?.info?.productPrice == 0 || product?.info?.productQuantity==0) && <NotifyButton classes="select-btn w-100" item={ product?.info}></NotifyButton>}
                                            </form>

                                            <div className="product-inner-category">
                                                <p><strong>Category : </strong> <a href="">{product.info && product.info.category && product.info.category.categoryName}</a>. </p>
                                            </div>

                                            <div className='product_description'> <h2>Product Description</h2>
                                                {product.info && product.info.productShortDescription}
                                            </div>
                                        </div>
                                    }
                                   {componentLoader== 0 
                                        && <><Skeleton /><Skeleton /> <Skeleton /> <Skeleton.Input /> <Skeleton.Input /></>
                                    }

                                </div>
                            </div>

                            {relatedproductListData.totalrecord > 0 &&
                                <div className="related-products-wrapper">
                                    <h2 className="related-products-title">Related Products</h2>

                                    <OwlCarousel className='owl-theme related-products-carousel' {...options}>
                                        {relatedproductListData.list.map((relatedItem, relatedKey) => {

                                           return (<div className="product__item-single" key={relatedItem?.id} title={relatedItem.productName}>
                                            <div className="product__item mb-20">
                                                <div className="product__thumb w-img fix">
                                                    <Link to={'/product/'+relatedItem?.productSku}>
                                                        {productobj.productImage(relatedItem, relatedproductListData.url)}
    
                                                    </Link>
                                                    <div className="product__action transition-3">
                                                        <ul>
                                                            <li>
                                                            {relatedItem.productPrice > 0 &&
                                                                <CartButton product={{ ...relatedItem, productImage: ((relatedItem?.productImage) ? relatedItem?.url + relatedItem?.productImage : "") }} classes="select-btn w-100"> <svg viewBox="0 0 22 22">
                                                                    <g>
                                                                        <path d="M18,19H6c-0.5,0-0.92-0.37-0.99-0.86L3.13,5H1C0.45,5,0,4.55,0,4s0.45-1,1-1h3c0.5,0,0.92,0.37,0.99,0.86L6.87,17h10.39
                                                                                    l2.4-8H11c-0.55,0-1-0.45-1-1s0.45-1,1-1h10c0.32,0,0.61,0.15,0.8,0.4c0.19,0.25,0.25,0.58,0.16,0.88l-3,10
                                                                                    C18.83,18.71,18.44,19,18,19z" />
                                                                    </g>
                                                                </svg></CartButton>}
                                                                {relatedItem.productPrice == 0 && <Link className="select-btn w-100 add_to_cart_button" to={`/product/` + relatedItem.productSku}><NotificationOutlined></NotificationOutlined></Link>}
    
                                                            </li>
                                                      
    
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="product__content text-center">
                                                    <div className="product__tag product__tag-4">
                                                        <span className="sku-box"> <strong> SKU </strong>  :  <Link to={`/product/` + relatedItem?.productSku}>{productobj.displaySKU(relatedItem)}</Link></span>
                                                    </div>
                                                    {cartobj.displayCondition(relatedItem)}
    
                                                    <h3 className="product__title">
                                                        {productobj.getProductName(relatedItem, true)}
    
                                                    </h3>
                                                    {productobj.displayPriceQuantity(relatedItem)}
                                                    {/*<div className="product__price product__price-4 mb-10">
                                                                    {productobj.ProductPrice(relatedItem) > 0 && <div className="price-box">
                                                                        <span className="price"><ins>{productobj.PriceDisplay(relatedItem)}</ins></span>
                                                                        {commonobj.isEmpty(cartobj.ProductCrossPrice(relatedItem))==false && <del>{cartobj.CrossPriceDisplay(relatedItem)}</del>}
                                                                         </div>
                                                    
                                                    }
                                                                 
                                                                        
                                                                        {cartobj.displayQuantity(relatedItem)}
                                                                       
                                                </div>*/}
                                                </div>
                                            </div>
                                        </div>)

                                        })}


                                    </OwlCarousel>
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>}
        <Footer></Footer>

    </>)

}
export default React.memo(Product);