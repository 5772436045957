import { Button, Form, Input, InputNumber,message, Space} from 'antd';
import { useNavigate , Link} from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import customerObj from '../../utility/customerClass'
import Header from '../Header/Header';
import Footer from '../Footer/index';

const { TextArea } = Input;
const Contact = (props) => {
    const page_title = {
        fontSize: "24px",
        lineHeight: "30px",
        fontWeight: "700",
        boxShadow: "0 -1px #eee inset",
        textTransform: "capitalize"
    }
    const page_title_wrapper = {
        display: "inline-block",
        padding: "5px 0 7px",
        borderBottom: "3px solid #01adeb"
    }
    const container_top = {       
        fontFamily: "Arial",
        margin:"10px"
    };
    const onFinish = async (values) => {
        console.log(values);
        const contact = await customerObj.contactUs(values);
        if(contact?.data?.length > 0){
            message.success('Submit success!');
        }
        
      };
    
    return (<>
        <Header></Header>
        <div className="container pt-5 mt-5">
   
        <div class="page-title-wrapper">
            <h1 class="page-title" style={page_title}>
                <span class="base" data-ui-id="page-title-wrapper" style={page_title_wrapper}>Contact Us</span>
            </h1>
        </div>
      
      <div class="contact_section d-flex">
      <div className="image_box" >
      <div class="mapouter">
        <div class="gmap_canvas">
        <iframe width="100%" height="600" id="gmap_canvas" src="https://maps.google.com/maps?q=650 Technology Park, Lake Mary, FL 32746, USA&t=&z=10&ie=UTF8&iwloc=&output=embed" frameborder="0" 
         marginheight="0" marginwidth="0"></iframe>
         
      
     
     </div>
     </div>   
                    </div> 
        <div class="contact_form">
        <legend class="legend">
            <span>Leave A Message</span>
        </legend>
        <div class="field note no-label">Jot us a note and we’ll get back to you as quickly as possible.</div>
        <hr/><br/>
        <Form      
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
            >
            <Form.Item
                name="name"
                label="Your Name"
          
                rules={[
                {
                    required: true,
                    whitespace: true,
                    message:'Please enter your name'
                },
                {
                    errorOnly: true,
                },
                ]}
            >
            <Input placeholder="Please enter your name" />
            </Form.Item>
            <Form.Item
                name="email"
                label="Your Email"
   
                rules={[
                {
                    required: true,
                    message:'Please enter your email'
                },
                {
                    type: 'email',
                    errorOnly: true,
                    message:'Please enter valid email'
                },
                ]}
            >
            <Input placeholder="Please enter your email" />
            </Form.Item>
            <Form.Item
                name="mobile"
                label="Your Mobile"
              
                rules={[
                {
                    required: true,
                    message:'Please enter your mobile no'
                },
                {
                    errorOnly: true,
                },
                ]}
            >
            <Input placeholder="Please enter your mobile number" />
            </Form.Item>
            <Form.Item 
                name="message"
                label="Your Message"
                rules={[
                {
                    required: true,
                    message: 'Please enter your message'
                },
                {
                    errorOnly: true,
                },
                ]}
            >
             <Input.TextArea rows={5} showCount maxLength={100} placeholder="Please enter your message" />
            </Form.Item>
            <Form.Item>
                <Space>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
                </Space>
            </Form.Item>
            </Form>
         </div>
       </div>
       </div>
        <Footer></Footer>


      

    </>)
}
export default Contact


