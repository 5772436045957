import React, {useState, useEffect} from 'react'
import "bootstrap/dist/css/bootstrap.min.css"
import { notification,Button, Checkbox, Form, Input } from 'antd';
import adminObj from '../../utility/adminClass'
//import utility from '../../../../api/utility';
  
export default function Social(){
    const metakey="socialSetting";
     const [sociallist,setList]=useState({}); 
      
     useEffect(()=>{          
          async  function  getSocialData(){
              let metaKey = {metaKey:metakey};
               const data = await adminObj.getSocial(metaKey);
               console.log(data);
               let tmpdata={};
               tmpdata= JSON.parse(data['metaValue'])
               setList(tmpdata);
               console.log(tmpdata);
               
          }
          getSocialData();
     },[])

     const onFinish = async (values) => {
        //if(utility.empty(values)){
            let data={metavalue:JSON.stringify(values),metakey:metakey}
            const saveData = await adminObj.updateSocial(data);
            console.log(saveData);
            notification.success({message:'Social Setting updated successfully'})
       // }
      };

          return (<>
               
               { Object.keys(sociallist).length > 0 && <Form
                    name="social"
                    labelCol={{
                        span: 8,
                    }}
                    initialValues={sociallist}
                    wrapperCol={{
                        span: 16,
                    }}   
                    onFinish={onFinish}                 
                    //autoComplete="off"
                    >
                    {
                    Object.keys(sociallist).length > 0 && Object.keys(sociallist).map((socialkey,key) => {
                        return(    
                    <Form.Item
                        label = {socialkey}
                        name = {socialkey}
                      
                        rules={[
                        {
                            required: true,
                            message: 'Please input your ' +{socialkey},
                        },
                        ]}
                    >
                    <Input />
                    </Form.Item>)
                    })} 
                    <Form.Item
                        wrapperCol={{
                        offset: 8,
                        span: 16,
                        }}
                    >
                        <Button type="primary" htmlType="submit">
                        Submit
                        </Button>
                    </Form.Item>
                    </Form>
               }
        </>  )   
}
