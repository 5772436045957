import { Button, Form, Input, Switch, Result } from 'antd';
import authObj from '../../utility/authclass'
import cartObj from '../../utility/cartClass'
import { useDispatch } from 'react-redux';
import { int } from '../../redux/action/userAction';
import { addToCartAction,emptyCart } from '../../redux/action/cartAction'
import { useNavigate , Link} from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/index';
import { useEffect } from 'react';
const Thanks = (props) => {
    const dispatch = useDispatch();
    const componentSize = 'large';
    const loginkey = 'login';
    useEffect(()=>{
        
        cartObj.emptyCartLocalStorage();
        dispatch(emptyCart());

    },[])


    return (<>
        <Header></Header>
        <div className="single-product-area">
            <div className="zigzag-bottom"></div>
            <div className="container">
                <div className="row">
                <div className="col-md-12">
                <Result
    status="success"
    title="Successfully Purchased with itsuperstore.us"
    subTitle="Order Detail send to your email address."
    extra={[
     <Link to="/cart"><Button type="primary" key="console">
        Go to Cart
      </Button></Link> ,
      <Link to="/"><Button key="buy">Buy Again</Button></Link>,
    ]}
  />
                </div>
            
                </div>
            </div>
        </div>
        
        <Footer></Footer>


      

    </>)
}
export default Thanks