
import productObj from '../../utility/productClass'
import customerObj from '../../utility/customerClass'
import { useState, useEffect } from 'react';
import { Empty, notification, Popconfirm } from 'antd';
import { Link, useParams } from 'react-router-dom';
import common from '../../utility/commonclass';
import orderObj from '../../utility/OrderClass';
const MyOrderDetail = (props) => {
    const param=useParams()
    const id = props?.id
    const [OrderDetail, setOrderDetail] = useState({});   
    useEffect(() => {
        console.log(`Order Id ${id}`);
        async function getData() {
            const orderData = await customerObj.getCustomerOrderDetail({ id: id });
            console.log(orderData);
            setOrderDetail(orderData);
        }
        getData();

    }, [id]);

    return (<>
        <div className="row">
            <div className="col-md-12">
                <div className="product-content-right">
                    <div className="woocommerce">

                        <div className="row invoice-info">
                     
                    <div className="col-sm-7 invoice-col">
                    <h4>Customer Address</h4>
                      <address>
                      <p><b>{`${OrderDetail?.customerAddress?.firstName} ${OrderDetail?.customerAddress?.lastName}`}</b></p>
                      <p> {OrderDetail?.customerAddress?.addressLine1}, {OrderDetail?.customerAddress?.addressLine2}, {OrderDetail?.customerAddress?.city?.name}, {OrderDetail?.customerAddress?.pincode}</p>
                      <p> {OrderDetail?.customerAddress?.state?.name} , {OrderDetail?.customerAddress?.country?.name} </p>                    
                   
                      <p><b>Mobile Number:</b></p> 
                      <p>{OrderDetail?.customerAddress?.mobileNumber}</p> 
                      <p><b>Email:</b></p>  
                      <p>{OrderDetail?.customerAddress?.primaryEmail}</p>  
                      </address>
                    </div>
                 
                    
                   
                    <div className="col-sm-5 invoice-col ">
                    <div className="d-flex flex-column justify-content-end align-items-end">
                      <p>Order Number #<b>{OrderDetail.id}</b></p>
                      <p>Order Date  :<b> {common.dateTimeFormat(OrderDetail.orderDate)}</b></p>               
                      <div className="form-group row">
                      <p> Order Amount  :<b> {orderObj.getOrderTotalDisplay(OrderDetail)}</b></p>         
                     </div>
                    </div>
                    </div>
                  
                  </div>
                  <div> <h3 className="card-header">Items Detail</h3></div>
                  <div className="card-form-box">
                  <div className="row">
                    <div className="col-12 table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th >S.R </th>
                           
                            <th >Product Name</th>
                            <th >Price</th>
                            <th >QTY</th>                            
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                               OrderDetail?.customerOrderItems?.length > 0&& 
                            OrderDetail?.customerOrderItems.map((item, itemkey) => (
                              <tr key={itemkey}>
                                <td >{(itemkey + 1)}</td>                              
                                <td >{orderObj.getOrderItemName(item)}</td>
                                <td >{orderObj.getOrderItemPrice(item,OrderDetail.currency)}</td>
                                <td >{orderObj.getOrderItemUnit(item)}</td>                              
                                <td>{orderObj.getOrderItemTotalPrice(item,OrderDetail.currency)}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>

                  </div>

{}
                  <div className="row">

                    <div className="col-6">
                      <p className="lead">Payment Detail:</p>
                      <b>Payment By</b>: {OrderDetail?.payment?.paymentLabel}  <br />
                      <b>Payment Status</b>: {orderObj.PaymentStatus(OrderDetail)}


                    </div>

                    <div className="col-6">

                      <div className="table-responsive">
                        <table className="table payment_table">
                          <tr>
                            <th style={{ width: "50%" }}>Subtotal:</th>
                            <td>{orderObj.getsubTotalwithCurrency(OrderDetail)}</td>
                          </tr>
                          <tr>
                            <th>Tax</th>
                            <td>{orderObj.getTaxWithCurrency(OrderDetail)}</td>
                          </tr>
                          <tr>
                            <th>Discount</th>
                            <td>{orderObj.getDiscountWithCurrency(OrderDetail)}</td>
                          </tr>
                          <tr>
                            <th>Shipping:</th>
                            <td>{orderObj.getShippingWithCurrency(OrderDetail) }</td>
                          </tr>
                          <tr>
                            <th>Total:</th>
                            <td>{orderObj.getOrderTotalDisplay(OrderDetail)}</td>
                          </tr>
                        </table>
                      </div>
                    </div>

                  </div>
                </div>

                    </div>
                </div>
            </div>
            <div className="col-md-3">




            </div>
        </div>
    </>)

}
export default MyOrderDetail;