import { useDispatch } from 'react-redux';
import HeaderLogo from './HeaderLogo'
import HeaderCart from './HeaderCart'
import { Link,useNavigate } from 'react-router-dom'
import { emptySession } from '../../redux/action/userAction';
import { emptyCart } from '../../redux/action/cartAction';
import HeaderSearch from './HeaderSearch'
import React, { useState,useEffect } from 'react';
import productObj from '../../utility/productClass'
import AuthObj from '../../utility/authclass';
import { DownOutlined, UserOutlined,PhoneOutlined,ShoppingOutlined,SelectOutlined,CalendarOutlined,MailOutlined } from '@ant-design/icons';

import { Button, Dropdown, message, Space, Tooltip,Menu } from 'antd';
const Header = function (props) {
    const dispatch = useDispatch();
    const navigate= useNavigate()
    const [categorylist,setlist]=useState([]);

    
    useEffect(()=>{
        async  function  getCategoryData(){
            const data =await productObj.getCategory({isHome:1});
            console.log('object');
            console.log(data);
            setlist(data);

        }

        getCategoryData();

    },[])


    const handleMenuClick = (e) => {
       // message.info('Click on menu item.');
        console.log('click', e.key);
        if(e.key=='profile'){
           
            navigate("/customer")
        }else if(e.key=='logout'){
            AuthObj.logout();
            dispatch(emptySession());
            dispatch(emptyCart());
            
            navigate("/")

        }else if(e.key=='myorder'){         
            navigate("/customer/myorder")
        }
      };

      const menu = (
        <Menu onClick={handleMenuClick}>
          <Menu.Item key="profile">         
           Profile
          </Menu.Item>
         
          <Menu.Item key="myorder">           
            My Orders
          </Menu.Item>
          <Menu.Item key="logout">           
           Logout
          </Menu.Item>
        </Menu>
      );
    

    return (<>
        <header>
            <div className="header__area">
                <div className="header__top black-bg ">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-5 col-xl-5 d-sm-none">
                                <div className="header__top-info text-center">
                                    <ul>
                                    <li><ShoppingOutlined style={{ fontSize:20, color: "white" }}/>Free Shipping</li>
                                    <li><SelectOutlined style={{ fontSize:20, color: "white" }} />Click & Collect</li>
                                    <li><CalendarOutlined style={{ fontSize:20, color: "white" }} />Warranty</li>
                                </ul>
                                </div>
                            </div>
                            
                            <div className="col-xxl-4 col-xl-4 d-sm-none">
                            <div className="header__top-info text-center">
                                    <ul>
                                        <li> <a href="tel:(877)328-6676"><PhoneOutlined style={{ fontSize:20, color: "white" }} />  (877)328-6676</a></li>
                                        <li><a href="mailto:sales@itsuperstore.us"><MailOutlined style={{ fontSize:20, color: "white" }} /> sales@itsuperstore.us</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xxl-3 col-xl-3">
                            <div className="d-flex align-items-center justify-content-sm-end">
                            <div className="header__top-info text-center d-none ">
                                    <ul>
                                        <li> <a href="tel:(877)328-6676"><PhoneOutlined style={{ fontSize:20, color: "white" }} />  (877)328-6676</a></li>

                                    </ul>
                                </div>
                                <div className="header_right text-center">
                               

                                    {AuthObj.isLogin()==false && <Link to="/login">Login</Link> }
                                    {AuthObj.isLogin()==true && <>
                                        <Dropdown.Button overlay={menu} icon={<UserOutlined></UserOutlined>}>
      {AuthObj.getCurrentName()}
    </Dropdown.Button>
                                   </> }

                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="header__bottom" id="myHeader">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xxl-1 col-xl-1 col-lg-3  col-3">
                                <HeaderLogo></HeaderLogo>
                            </div>

                            {props.hideheader !=1 && <div className="col-xxl-9 col-xl-9 col-lg-8 col-4 ">
                                <div className="category__menu d-flex align-items-center">
                                    <div className="category__btn mr-20">
                                        <button className="cat-btn" type="button"><i className="fa fa-bars"></i><span className='cat_name'>All Categories</span></button>
                                        <div className="side-submenu transition-3">
                                        <div className="container">
                                            <nav>
                                                <ul>
                                                    
                                                    {categorylist.map((item)=>{
                                                        return ( <li key={item.id}>
                                                            <Link to={productObj.productcategoryUrl(item)}>
                                                                <i className="fa fa-tshirt"></i>
                                                    {item.categoryName}
                                                    </Link>
                                                        </li>)
                                                    })}
                                                    
                                                </ul>
                                            </nav>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-xxl-9 col-xl-9 col-lg-8 d-lg-block header-search-box">
                                    <div className="header__bottom-right d-xl-flex align-items-center justify-content-end">
                                    <HeaderSearch></HeaderSearch>
                                    </div>
                                    </div>
                                 
                                </div>
                            </div>}
                           
                           

                            {props.hideheader !=1 && <div className="col-xxl-2 col-xl-2  col-5">
                                <HeaderCart></HeaderCart>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </header>

    </>)

}
export default Header;
