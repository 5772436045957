import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from "react-router-dom"
import 'font-awesome/css/font-awesome.min.css'
import productObj from '../../utility/productClass'
import React, { useState,useEffect } from 'react';

const options = {
    margin: 10,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: false,
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 3,
        },
        1000: {
            items: 4,

        }
    },
};
const TopCatagory = function (props) {
    
    const [list,setlist]=useState([])
    useEffect(()=>{
        async  function  getCategoryData(){
            const data =await productObj.getCategory({isHome:1});
            console.log(data);
            setlist(data);
        }

        getCategoryData();


        

    },[])
    return (<>
   
    <section className="category__area pt-40 pb-40  grey-bg-3">
        <div className="container">
            <div className="row">
                <div className="col-xxl-12">
                    <div className="section__title-wrapper section__title-line mb-35">
                        <h3 className="section__title">Top Categories</h3>
                    </div>
                </div>
            </div>
            <div className="row">
            <div className="col-xxl-12 col-xl-12 col-lg-12">
                {list.length > 0 && 
            <OwlCarousel className="product__item-slider common-nav owl-carousel"{...options}  >
                {list.map((item)=>{
                    return (<div className="col-xxl-12 col-xl-12 col-lg-4 col-md-4 col-sm-6" key={item.id}>
                    <div className="category__item mb-30">
                        <div className="category__thumb w-img fix">
                            <Link to={productObj.categoryUrl(item)}>
                                {productObj.categoryImage(item)}
                              
                            </Link>
                        </div>
                        <div className="category__content text-center">
                            <h3 className="category__title">
                                <Link to={productObj.categoryUrl(item)}>{item.categoryName}</Link>
                            </h3>
                           
                        </div>
                    </div>
                </div>)
                })}
                </OwlCarousel>
    } 
    </div>
            </div>
        </div>
    </section>
    </>)

}
export default TopCatagory;