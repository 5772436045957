import { Button, Form, Input, Switch,notification } from 'antd';
import {  SyncOutlined} from '@ant-design/icons';
import { useState, useEffect } from 'react';
import CustomerObj from '../../utility/customerClass';
import { useNavigate , Link} from 'react-router-dom';
const COD = (props) =>{

    const navigate= useNavigate()
  const order=props.order;
  console.log('payment');
  console.log(order);
let [captchaurl,setCaptchaUrl]=useState("");
let [captchaNumber,setCaptchaNumber]=useState("");


const createCaptcha=()=> {
    let code="";
  //clear the contents of captcha div first 
  //document.getElementById('captcha').innerHTML = "";
  var charsArray =
  "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ@";
  var lengthOtp = 6;
  var captcha = [];
  for (var i = 0; i < lengthOtp; i++) {
    //below code will not allow Repetition of Characters
    var index = Math.floor(Math.random() * charsArray.length + 1); //get the next character from the array
    if (captcha.indexOf(charsArray[index]) == -1)
      captcha.push(charsArray[index]);
    else i--;
  }
  var canv = document.createElement("canvas");
  canv.id = "captcha";
  canv.width = 100;
  canv.height = 50;
  var ctx = canv.getContext("2d");
  ctx.font = "25px Georgia";
  ctx.strokeText(captcha.join(""), 0, 30);
  //storing captcha so that can validate you can save it somewhere else according to your specific requirements
  code = captcha.join("");
  setCaptchaNumber(code);

  setCaptchaUrl(canv.toDataURL());
 //document.getElementById("captcha").appendChild(canv); // adds the canvas to the body element
}


useEffect(() => {
  createCaptcha();

}, [])



const onFinish = async (values) => {
  if(values.captcha===captchaNumber){
    props.updatepayment();
    //props.validateCaptcha();

  }else{
    notification.error({message:'Invalid Captcha'})
    createCaptcha();
  }   

};

const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
};
const onReset = () => {
  console.log('reset');
  createCaptcha();
};
    return (
        <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
              
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
            <img src={captchaurl}  alt=""/>
                <Button htmlType="button" onClick={onReset}>
                  <SyncOutlined />
                </Button>
                <Form.Item
                   name="captcha"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Username!',
                        },
                    ]}
                >
                  <Input className={"form-control"} placeholder="Captcha" />
                 

                </Form.Item>
                
                <Form.Item>
                <Button type="primary" htmlType="submit">
                  Place Order
                </Button>
                </Form.Item>

            </Form>

    )

}
 export default COD;