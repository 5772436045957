import React, {useState, useEffect} from 'react'
import "bootstrap/dist/css/bootstrap.min.css"
//import Axios from "axios"
import {  Switch, Pagination,Empty, notification,Input, Space  } from 'antd';
import categoryObj from '../../utility/productClass'

import adminObj from '../../utility/adminClass'
const { Search } = Input;
export default function Category(){

     const [catlist,setList]=useState({list:[],count:0});
     const [filltercatlist,setfilltercatlist]=useState({list:[],count:0});  
     const [paging, setpage] = useState({ currentpage: 1, itemlimit: 40 });
     const [filter, setFilter] = useState({  });
     const [webinput, setWebInput] = useState({});
    const [qtyinput, setQtyInput] = useState({});
    const [sortinput, setSortInput] = useState({});
    
     /*********Token send***********/  
     let token = sessionStorage.getItem('_adminitstore');
     /**********End**********/
   
     useEffect(()=>{          
          async  function  getCategoryData(){
               const data =await categoryObj.getCategoryPagination({ page: paging.currentpage, limit: paging.itemlimit, filter: filter },{headers: {"Authorization" : `Bearer ${token}`} });
               console.log(data);
               if(data.categorydata.rows?.length > 0){
                    let obj={};
                    let objQty={};
                    let objSort={}
                    for(let i =0; i<data.categorydata.rows?.length;i++){
                         obj[data.categorydata.rows[i]['id']]=data.categorydata.rows[i]['categoryName'];
                         objQty[data.categorydata.rows[i]['id']]=data.categorydata.rows[i]['categoryQty'];
                         objSort[data.categorydata.rows[i]['id']]=data.categorydata.rows[i]['categorySort'];
                    }
                    setWebInput(obj);
                    setQtyInput(objQty);
                    setSortInput(objSort);
               }

               setList({list:data.categorydata.rows,count:data.categorydata.count});
               setfilltercatlist({list:data.categorydata.rows,count:data.categorydata.count});
          }
          getCategoryData();
     },[paging])

     const handlePageChange = (page) => {
          setpage((pre) => {
              return { ...pre, currentpage: page }
          })
     } 

     const onchangeWebCat = (name,id) => {
          let ob={};
          ob[id]=name;
          setWebInput((pre) => {
              return { ...pre, ...ob }
          })
     } 

     const onchangeCatQty = (name,id) => {
          let ob={};
          ob[id]=name;
          setQtyInput((pre) => {
              return { ...pre, ...ob }
          })
     } 

     const onchangeCatSort = (name,id) => {
          let ob={};
          ob[id]=name;
          setSortInput((pre) => {
              return { ...pre, ...ob }
          })
     } 


     
     
     const onChange = async  (id,checked) => {
          const data = {category:id,status:(checked==true)?1:0}  
          const response =await categoryObj.activateHomeCategory(data);   
          notification.success({message:'Update successfully',placement: 'bottomRight'})
        };
     

     const handleClick = async(id,type) => {
        let val;
        if(type=='category'){
          val=webinput[id];
        }else if(type=='categorySort'){
          val=sortinput[id];
        }else{
          val=qtyinput[id];             
        }
          const data = {id:id,type:type,value:val} 
          console.log(data); 
          const response =await adminObj.changeCategoryQty(data);   
          notification.success({message:'Update successfully',placement: 'bottomRight'})
     }


     
     

const onSearch = (value, _e) => {
let list=[...catlist?.list];
let newlist=list.filter((item,key)=>{
     return item?.erpCategory?.toLowerCase().includes(value?.toLowerCase());

})
setfilltercatlist((pre)=>({...pre,list:newlist}))
console.log(newlist);

};
          return (
               <>
               <h1>Category</h1>
               <div className="container">               
               <div className="row" style={{paddingTop:10,paddingBottom:10}}>
                    <div className="col-md-6" >
                    
                    <Search placeholder="Search Erp Category" onSearch={onSearch} enterButton />
                    </div>
               </div>
               <div className="row">
                   
                
                    <div className="col-md-12"> <table className="table table-light category-list">
                         <thead>
                         <tr>
                              <th scope="col">Sr No</th>
                              <th scope="col">ERP Category</th>
                              <th scope="col">Category Name</th>
                              <th scope="col">Qty for Free Shipping</th>
                              <th scope="col">Sort Order</th>
                              <th scope="col">Active Home</th>
                         </tr>
                         </thead>
                         <tbody>
                              {
                              filltercatlist.list.length > 0 && filltercatlist.list.map((catdata,sr) => {
                                   return(
                                   <tr key={sr}>
                                        <td>{sr+1}</td>
                                        <td>{catdata.erpCategory}</td>
                                        <td> <input type="text" className="cat-input" value={webinput[catdata?.id]} onChange={e => onchangeWebCat(e.target.value,catdata?.id)} />
                                       
                                         <i className="fa fa-floppy-o cursor-pointer" onClick={() => handleClick(catdata.id,'category')}></i>
                                      </td>
                                        <td><input type="number" className="catqty cat-input" value={qtyinput[catdata?.id]} onChange={e => onchangeCatQty(e.target.value,catdata?.id)}/>
                                             <i className="fa fa-floppy-o cursor-pointer" onClick={() => handleClick(catdata.id,'category_qty')}></i>
                                        </td>
                                        <td><input type="number" className="catqty cat-input" value={sortinput[catdata?.id]} onChange={e => onchangeCatSort(e.target.value,catdata?.id)}/>
                                             <i className="fa fa-floppy-o cursor-pointer" onClick={() => handleClick(catdata.id,'categorySort')}></i>
                                        </td>
                                        <td>
                                        <Switch defaultChecked={catdata.isHome} onChange={(e)=>onChange(catdata.id,e)} />
                                           </td>
                                        
                                   </tr>)
                                   
                              })}                         
                         </tbody>
                     </table>
                     </div>
                    <div className="col-md-12"> <Pagination defaultPageSize={40} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`} defaultCurrent={paging.currentpage} total={catlist.count} onChange={handlePageChange} /></div>
              
               </div></div>  </>
          )   
}
