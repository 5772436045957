import React, {useState, useEffect} from 'react'
import "bootstrap/dist/css/bootstrap.min.css"
import {Button,Modal,Pagination,Popconfirm, notification,Switch,Checkbox,Radio} from 'antd';
import CareerObj from '../../../utility/adminClass'
  
export default function List(){
     const [careerlist,setList]=useState({list:[],count:0}); 
     const [paging, setpage] = useState({ currentpage: 1, itemlimit: 20 });
     const [isChecked, setIsChecked] = useState(0);
     const [Domestic, setDomestic] = useState({});
     const [International,setInernational] = useState({});
     /*********Token send***********/  
     let token = sessionStorage.getItem('_adminitstore');
     /**********End**********/
     async  function  getCareerListData(){
          const data =await CareerObj.getCareerManagement({ page: paging.currentpage, limit: paging.itemlimit},{headers: {"Authorization" : `Bearer ${token}`} });
          //console.log(data);
          if(data.rows.length > 0){
               let objDomestic = {};
               let objInternational = {};
               for(let i=0; i<data.rows.length; i++){
                    objDomestic[data.rows[i]['fedexID']] = data.rows[i]['isDomestic'];
                    objInternational[data.rows[i]['fedexID']] = data.rows[i]['isInternational'];
               }
               setDomestic(objDomestic);
               setInernational(objInternational);
          }
          setList({list:data.rows,count:data.count});
     }
     useEffect(()=>{          
         
          getCareerListData();
     },[paging])

     const handlePageChange = (page) => {
          setpage((pre) => {
              return { ...pre, currentpage: page }
          })
     } 
    
     async function handleChangeData(e,id,type) {          
          let status='';
          (e.target.checked==true)?status=1:status=0; 
          if(type=='Domestic'){
               let ob={};
               ob[id]=status;
               setDomestic((pre) => {
                   return { ...pre, ...ob }
               })
          }else{
               let obj={};
               obj[id]=status;
               setInernational((pre) => {
                   return { ...pre, ...obj }
               })
          }  
          
          const data = await CareerObj.editDomesticInternation({id:id,type:type,status:status});
          console.log(data);
          if(data?.data?.length > 0){ 
               notification.success({message:'Data has been updated successfullly',placement:'bottomRight'});                   
          }
          //getCareerListData();
     }

     async function handleChangeShiipingData(e,id){
          let status='';
          (e.target.value==true)?status=1:status=0;        
          setIsChecked(id); 
          const data = await CareerObj.editCareerFreeShipping({id:id,status:status});
          getCareerListData();
          if(data?.data?.length > 0){ 
               notification.success({message:'Data has been updated successfullly',placement:'bottomRight'}); 
          }         
     }
     
    
          return (
               <div>
                                       
                     <table className="table table-light">
                         <thead>
                         <tr>
                              <th scope="col">No</th>
                              <th scope="col">Service Type</th>
                              <th scope="col">Service Value</th>
                              <th scope="col">Domestic</th>
                              <th scope="col">International</th>
                              <th scope="col">Free Shipping</th>
                         </tr>
                         </thead>
                         <tbody>
                              {
                              careerlist.list.length > 0 && careerlist.list.map((careerdata,sr) => {                                   
                              return(
                                   <tr>
                                        <td>{sr+1}</td>
                                        <td>{careerdata.service_type}</td>
                                        <td>{careerdata.service_value}</td>
                                        <td><Checkbox checked={(Domestic[careerdata.fedexID]==1)?'checked':''} onChange={e => handleChangeData(e,careerdata.fedexID,'Domestic')}/></td>
                                        <td><Checkbox checked={(International[careerdata.fedexID]==1)?'checked':''} onChange={e => handleChangeData(e,careerdata.fedexID,'International')}/></td>
                                        <td>
                                             <Radio name="freeshipping" checked={(isChecked===careerdata.fedexID || careerdata.isFreeShipping==1)?true:false} onChange={(e)=>handleChangeShiipingData(e,careerdata.fedexID)} value={1}></Radio>

                                        </td>
                                   </tr>)                                   
                              })}                         
                         </tbody>
                     </table>
                 <Pagination defaultPageSize={20} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`} defaultCurrent={paging.currentpage} total={careerlist.count} onChange={handlePageChange} />
              
               </div>  
          )   
}
