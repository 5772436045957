import config from '../config/index'
import common from '../utility/commonclass';
import { Skeleton } from 'antd';
import {getProductVariantService,callApi,getProducts} from '../services/product.service'
import { useParams, Link } from 'react-router-dom';
import MyImage from '../components/Common/MyImage';
import cartObj from '../utility/cartClass';
import { Image } from 'antd';
class Product {

    static myInstance = null;
    curreny = '$';
    cartData = {}
    constructor() {

    }
    static getInstance() {
        if (Product.myInstance == null) {
            Product.myInstance = new Product();
        }

        return this.myInstance;
    }
    getProductName(product, islink, ...arg) {
        if (islink === true) {
            return <Link to={`/product/${product.productSku}`}>{product && product.productName}</Link>
        } else {
            return product && product.productName;
        }


    }

    getProductSku(product, ...arg) {

            if(common.isEmpty(product?.productSku)==false){
              return product?.productSku;
            }else{
                return "";
            }
       
    }

    ProductPrice(product) {
        if(!product.productVariantPrice)
        return product.productPrice;
        else{
            return product.productVariantPrice;
        }
    }
    ProductCrossPrice(product) {
        return product.productCrossPrice;
    }

    PriceDisplay(product) {
        if(this.ProductPrice(product) > 0)
        return this.curreny + this.ProductPrice(product);
        else
        return "";
    }

    CrossPriceDisplay(product) {
        return this.curreny + this.ProductCrossPrice(product);
    }
    displayPriceQuantity(item) {
                
        return <div className="row product__price product__price-4 mb-10">    
            <div className="col-md-5" style={{textAlign: "left"}}>
                {cartObj.displayQuantity(item)} 
            </div>
            <div className="col-md-7" style={{textAlign: "right"}}>
                
                {this.ProductPrice(item) > 0 && item?.productCrossPrice > item?.productPrice &&  common.isEmpty(cartObj.ProductCrossPrice(item))==false && <del>{cartObj.CrossPriceDisplay(item)}</del>}             
                {this.ProductPrice(item) > 0 &&  <span className="price"><ins>{cartObj.PriceDisplay(item)}</ins></span>}  
            </div>
                                                             
        </div>
    }

    productImage(product = {}, baseurl="", arg = {}) {
    //console.log(product);
        if (arg.isplaceholder === true && !product.productName) {
            return <div className="product-images-not-load"><Skeleton.Image active={true} width={500} /></div>
        } else if (product.productImage) {
            //console.log('abbasf');
            return <MyImage alt={product.productName} helght={240} width={230} caption={product.productName} src={baseurl + product.productImage}></MyImage>
            return <img src={baseurl + product.productImage} alt="" />
        } else {
            return <img src={require("../images/product.png")} alt="" />
        }
    }

    ShowImage(index = {}, media=[], arg = {}) {
        //console.log(product);
           if (media[index]) {
                return <MyImage alt={"Image"} helght={240} width={230} caption={"Image"} src={media[index]}></MyImage>
               
            } else {
                return <img src={require("../images/product.png")} alt="" />
            }
        }

    productGallery(media = [], baseurl, isplaceholder = true) {
        return <div className="product-gallery">
            {
                media.map((mediaItem, mediaKey) => {

                     if (common.isEmpty(mediaItem?.mediaName)==false) {
                        return <div className="gallery-item"><MyImage alt={mediaItem?.mediaName} helght={200} width={200} caption={mediaItem?.mediaName} src={baseurl + mediaItem?.mediaName} afterLoad={()=>{console.log('After Load call')}}></MyImage></div>
                       
                    } else {
                        return <img src={require("../images/product.png")} alt="" />
                    }
                   /* return (<img src={baseurl + mediaItem?.mediaName} alt="" />)*/

                })
            }
        </div>
    }

   async getProductVariant(productId){
    try{
        return  await getProductVariantService(productId);
    }catch(e){
        console.warn(e);
        return []
    }


    }

    async getCategory(arg={}){
        try{
            return  await callApi('/product/getCategory',arg);
        }catch(e){
            console.warn(e);
            return []
        }
    }

    async getCategoryName(arg={}){
        try{
            return  await callApi('/product/getCategoryName',arg);
        }catch(e){
            console.warn(e);
            return []
        }
    }

    async getCatgorywithCount(arg={}){
        try{
            return  await callApi('/product/getCatgorywithCount',arg);
        }catch(e){
            console.warn(e);
            return []
        }
    }

    

    categoryImage(category = {}, baseurl="", arg = {}) {
        if (arg.isplaceholder === true && !category.categoryName) {
            const width=(arg.isplaceholderWidth)?arg.isplaceholderWidth:500;
            return <div className="product-images-not-load"><Skeleton.Image active={true} width={width} /></div>
        } else if (category.categoryImage) {
            return <img src={category.categoryImage} alt="" />
        } else {
            return <img src={require("../images/product.png")} alt="" />
        }

    }

    categoryUrl(category = {}) {
        return "/productcategory/?category="+category.id;
        //return "/product/?category="+category.id;
 
     }
     productcategoryUrl(category = {}) {
        return "/productcategory/?category="+category.id;
 
     }


    async getProductData(arg){
        try{    
            const data= await getProducts(arg);
            return data;
        }catch(e){
            return []
        }
        

    }

    async getCategoryPagination(arg={}){
        try{
            return  await callApi('/product/getCategoryPagination',arg);
        }catch(e){
            console.warn(e);
            return []
        }
    }

    async activateHomeCategory(arg={}){
        try{
            return await callApi('/product/activateHomeCategory',arg);
        }catch(e){
            console.log(e);
            return []
        }
    }
    async getConditions(arg={}){
        try{
            return await callApi('/getconditions',arg);
        }catch(e){
            console.log(e);
            return []
        }
    }
    async getManufacturer(arg={}){
        try{
            return await callApi('/getmanufacturers',arg);
        }catch(e){
            console.log(e);
            return []
        }


    }

    


    displaySKU(item){
        if(item?.productrefType=='main-condition' || item?.productrefType=='alias-condition'){
            if(item?.erp_condition!=''){
                let condition=item?.erp_condition;
                condition= condition.replace(" ", "-").toLowerCase();
                let arr=item?.productSku.toLowerCase().split('-'+condition);
               
                return arr[0];
            }else{
                let replacevalue="";
                if(item?.productSku.includes("certified-refurbished")==true){
                    replacevalue= "certified-refurbished";
                }else if(item?.productSku.includes("third-party")==true){
                    replacevalue= "third-party";
                }else if(item?.productSku.includes("used-no-hologram")==true){
                    replacevalue= "used-no-hologram";
                }else if(item?.productSku.includes("new-0-hrs")==true){
                    replacevalue= "new-0-hrs";
                }else if(item?.productSku.includes("new-bulk")==true){
                    replacevalue= "new-bulk";
                }else if(item?.productSku.includes("new-sealed-spares")==true){
                    replacevalue= "new-sealed-spares";
                }else if(item?.productSku.includes("blank-trays")==true){
                    replacevalue= "blank-trays";
                }else if(item?.productSku.includes("new-retail")==true){
                    replacevalue= "new-retail";
                }else if(item?.productSku.includes("new-open-box")==true){
                    replacevalue= "new-open-box";
                }else if(item?.productSku.includes("hp-renew")==true){
                    replacevalue= "hp-renew";
                }else if(item?.productSku.includes("no-tray")==true){
                    replacevalue= "no-tray";
                }else if(item?.productSku.includes("used")==true){
                    replacevalue= "used";
                }
                let arr=item?.productSku.toLowerCase().split('-'+replacevalue);
               
                return arr[0];



            }
           
        }else{
            return item?.productSku;
        }
       
    }

}
let Instance = Product.getInstance();
export default Instance;