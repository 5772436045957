import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from "react-router-dom";
import { AutoComplete, Input } from 'antd';
import React, { useState,useEffect } from 'react';

import Header from './components/Header/Header';
import HomeSlider from './components/Header/HomeSlider'
import TopCatagory from './components/Category/TopCategory'
import CategoryProduct from './components/Category/CaptegoryProduct'
import Footer from './components/Footer/index';
import productObj from './utility/productClass'
const Home= function(){
    const [categorylist,setlist]=useState([]);

    
    useEffect(()=>{
        async  function  getCategoryData(){
            const data =await productObj.getCategory({isHome:1});
            console.log(data);
            setlist(data);

        }

        getCategoryData();

    },[])

return (<>

    <Header></Header>   
    <HomeSlider></HomeSlider>
    <section className="features__area pb-70">
    <div className="container">
        <div className="row">
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                <div className="features__item text-center mb-30 features__item-border">
                    <div className="features__icon">
                        <i className="fa fa-truck"></i>
                    </div>
                    <div className="features__content">
                        <h3 className="features__title">Free Shipping</h3>
                        <p className="text-center">Capped $39 per order</p>
                    </div>
                </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                <div className="features__item text-center mb-30 features__item-border">
                    <div className="features__icon">
                        <i className="fa fa-credit-card"></i>
                    </div>
                    <div className="features__content">
                        <h3 className="features__title">Secure Payments</h3>
                        <p className="text-center">Up to 12 months installments</p>
                    </div>
                </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                <div className="features__item text-center mb-30 features__item-border">
                    <div className="features__icon">
                        <i className="fa fa-exchange"></i>
                    </div>
                    <div className="features__content">
                        <h3 className="features__title">90-Day Returns</h3>
                        <p className="text-center">Shop with confidence</p>
                    </div>
                </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                <div className="features__item text-center mb-30">
                    <div className="features__icon">
                        <i className="fa fa-user"></i>
                    </div>
                    <div className="features__content">
                        <h3 className="features__title">24/7 Support</h3>
                        <p className="text-center">Capped $39 per order</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    <TopCatagory></TopCatagory>


    {
        categorylist.map((categoryItem)=>{

            return (
                <section className="product__area pt-95 pb-80" key={categoryItem.id}>
                        <div className="container">
                        <div className="row">
                                <CategoryProduct category={categoryItem}></CategoryProduct>
                        </div>  
                        </div>
                </section>

            )

        })
    }
  

     <Footer></Footer>

</>)

}

export default Home;
