//,DELETE_CART,UPDATE_CART,INCREASE_QUANTITY,DECREASE_QUANTITY,EMPTY_CART
import { SET_DATA, EMPTY_DATA, UPDATE_DATA } from '../action/userAction';
import authObj from '../../utility/authclass'
const initial = {}

function userReducer(state = initial, action) {
    switch (action.type) {
        case SET_DATA:
            return authObj.loadData(state);
        case UPDATE_DATA:           
           return {...state,user:{...state.user,...action.payload}}
        case EMPTY_DATA:
            return {}
        default:
            return state;

    }
}
export default userReducer;

