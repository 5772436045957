
import {Navigate,useLocation} from 'react-router-dom';
import adminauthClass from './utility/adminauthclass';
const Protectedadmin =({children} )=>{ 
    const location = useLocation();          
    if(adminauthClass.getUserSession()===false){
        //console.log('protected');
        //console.log(location.pathname);
        adminauthClass.updateRedirectpath(location.pathname);
        return <Navigate to="/admin/login" replace />;
    }
   
    return children;


}

export default Protectedadmin;