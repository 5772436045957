import { Button, Form, Input, Switch, Result } from 'antd';
import authObj from '../../utility/authclass'
import { useDispatch } from 'react-redux';
import { int } from '../../redux/action/userAction';
import { useNavigate , Link} from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/index';
const Cancel = (props) => {
    
    const componentSize = 'large';
    const loginkey = 'login';


    return (<>
        <Header></Header>
        <div className="single-product-area">
            <div className="zigzag-bottom"></div>
            <div className="container">
                <div className="row">
                <div className="col-md-12">
                <Result
    status="error"
    title="Failed Transcation"
    subTitle=""
    extra={[
     <Link to="/cart"><Button type="primary" key="console">
        Go to Cart
      </Button></Link> ,
      <Link to="/"><Button key="buy">Buy Again</Button></Link>,
    ]}
  />
                </div>
            
                </div>
            </div>
        </div>
        
        <Footer></Footer>


      

    </>)
}
export default Cancel