
import productObj from '../../utility/productClass'
import customerObj from '../../utility/customerClass'
import { useState, useEffect } from 'react';
import { Empty, notification, Popconfirm, Modal } from 'antd';
import   MyOrderDetail from '../Customer/MyOrderDetail'
import { Link } from 'react-router-dom';
import common from '../../utility/commonclass';

const MyOrder = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [orderdetailId, setorderdetailId] = useState(null);
    const showModal = (id) => {
        setorderdetailId(id);
      setIsModalOpen(true);
    };
    const handleOk = () => {
        setorderdetailId(null);
      setIsModalOpen(false);
    };
    const handleCancel = () => {
        setorderdetailId(null);
      setIsModalOpen(false);
    };
    const [OrderListData, setOrderList] = useState({ totalrecord: 0, list: [] });
    const [paging, recored] = useState({ currentpage: 1, itemlimit: 10 });
    useEffect(() => {
        async function getData() {
            const orderData = await customerObj.getCustomerOrder({});
            setOrderList({ totalrecord: orderData.count, list: orderData.rows })
        }
        getData();

    }, []);

    return (<>
        <div className="row">
            <div className="col-md-12">
                <div className="product-content-right">
                    <div className="woocommerce">
                        <form method="post" action="#" className="table-responsive">
                            <table cellspacing="0" className="shop_table cart table">
                                <thead>
                                    <tr>
                                        {/*<th className="product-remove">S.No</th>*/}
                                        <th className="customer-name">Id</th>
                                        {/*<th>Customer Name</th>*/}
                                        <th>Email</th>
                                        <th>Mobile</th>
                                        <th>Date</th>
                                        <th>Order</th>
                                        <th>Payment</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        OrderListData.list.map((item, itemkey) => {
                                            return (
                                                <tr key={item.id}>
                                                {/*<td >{((paging.currentpage - 1) * paging.itemlimit) + (itemkey + 1)}</td>*/}
                                                <td>
                                           
                                                    <Link className="order_id_detail" id={item.id} onClick={(e)=>showModal(item.id)} >{item.id}</Link></td>
                                                {/*<td >{item.customer['firstName']  +' '+  item.customer['lastName']}</td>*/}
                                                <td >{item.email}</td>
                                                <td >{item.mobile}</td>
                                                <td >{common.dateFormat(item.orderDate)}</td>
                                                <td >{item.orderStatus}</td>
                                                <td > {(item.paymentStatus == '1') ? 'Success' : 'Pending'}
                                                     </td>
                                                <td className='d-flex'>{(item.currency)+' '+(item.totalPrice)}</td>
                                            </tr>
                                            )
                                        })
                                    }
                                    {OrderListData.list.length == 0 && <tr><td colSpan="7"><Empty description="There is no order"></Empty></td></tr>}

                                </tbody>
                            </table>
                        </form>

                        <Modal className='order_detail_popup'
        title="Order Detail"
        open={isModalOpen}
        onOk={handleOk}
        width={767}
        onCancel={handleCancel}
      >
       {orderdetailId !=null && <MyOrderDetail id={orderdetailId} />} 
      </Modal>
                    </div>
                </div>
            </div>
            <div className="col-md-3">




            </div>
        </div>
    </>)

}
export default MyOrder;