import AuthObj from '../../utility/authclass';
import customerObj from '../../utility/customerClass'
import { InputNumber, notification,Modal,Form, Input, Button } from 'antd';
import React, { useState, useEffect,memo } from 'react';
import { Link } from 'react-router-dom';
const NotifyButton = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
   // const [getItem, setItem] = useState({});
    const [form] = Form.useForm();
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
      
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
    const Notifyme = async (e) => {
            console.log('Notify Me');
            //console.log(props.item.id);
            //setItem(props.item.id);
            setIsModalOpen(true);
    }

    const onFinish = async (values) => {
        //console.log(getItem);
       try {
            customerObj.notifyProduct(values); 
            notification.success({message:"Your Information has been saved successfully. When product is available then we will Notify You"})
            setIsModalOpen(false);
        } catch (e) { 
            notification.error({message:"There is something went wrong"})
            
        }
        
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (<>
        <Link className={`${props.classes} add_to_cart_button`} rel="nofollow" to="" onClick={(e) => Notifyme()}>
            {props.children && props.children}
            {!props.children && 'Notify Me'}

        </Link>
        <Modal title="Notify Me" visible={isModalOpen} onOk={form.submit} onCancel={handleCancel}>
        <Form
                name="basic"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
               // initialValues={getItem}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                form={form}
            >

<Form.Item
                    name="firstName"
                    label="First Name"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your First Name!',
                        },
                    ]}

                >
                    <Input className={"form-control"} placeholder="First Name" />
                </Form.Item>
                <Form.Item
                    name="lastName"
                    label="Last Name"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Last Name!',
                        },
                    ]}

                >
                    <Input className={"form-control"} placeholder="Last Name" />
                </Form.Item>

                

                <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Email!',
                        },
                        { type: 'email' }
                    ]}

                >
                    <Input className={"form-control"} placeholder="Email" />
                </Form.Item>

                <Form.Item
                    name="mobile"
                    label="Mobile"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Mobile!',
                        }
                    ]}

                >
                    <Input className={"form-control"} placeholder="Mobile" />
                </Form.Item>
                
                </Form>
      </Modal>
    </>)

}

export default memo(NotifyButton);