import config from '../config/index'
import common from '../utility/commonclass';
import { Skeleton } from 'antd';
import {callApi} from '../services/admin.service'
class Admin {

    static myInstance = null;
    
    constructor() {

    }
    static getInstance() {
        if (Admin.myInstance == null) {
            Admin.myInstance = new Admin();
        }

        return this.myInstance;
    }

    async getSocial(arg={}){
        try{
            return  await callApi('/admin/getSocial',arg);
        }catch(e){
            console.warn(e);
            return []
        }
    }
    async updateSocial(arg={}){
        try{
            return  await callApi('/admin/updateSocial',arg);
        }catch(e){
            console.warn(e);
            return []
        }
    }

    async getScrapeSite(arg={}){
        try{
            return  await callApi('/admin/getScrapeSite',arg);
        }catch(e){
            console.warn(e);
            return []
        }
    }
    async activateScrapeSite(arg={}){
        try{
            return  await callApi('/admin/activateScrapeSite',arg);
        }catch(e){
            console.warn(e);
            return []
        }
    }
    async scrapeSettingEdit(arg={}){
        try{
            return  await callApi('/admin/scrapeSettingEdit',arg);
        }catch(e){
            console.warn(e);
            return []
        }
    }
    async getScrapeSetting(arg={}){
        try{
            return  await callApi('/admin/getScrapeSetting',arg);
        }catch(e){
            console.warn(e);
            return []
        }
    }
    
    

    async emailSubscription(arg={}){
        try{
            return  await callApi('/user/email-subscription',arg,'object');
        }catch(e){
            console.warn(e);
            return []
        }
    }

    async getSeoPagination(arg={}){
        try{
            return  await callApi('/admin/getSeoPagination',arg);
        }catch(e){
            console.warn(e);
            return []
        }
    }

    async getSeo(arg={}){
        try{
            return  await callApi('/admin/getSeo',arg);
        }catch(e){
            console.warn(e);
            return []
        }
    }
    async getSeoEdit(arg={}){
        try{
            return  await callApi('/admin/getSeoEdit',arg);
        }catch(e){
            console.warn(e);
            return []
        }
    }

    async getShippingRules(arg={}){
        try{
            return  await callApi('/admin/getShippingRules',arg);
        }catch(e){
            console.warn(e);
            return []
        }
    }
    async getShippingRulesById(arg={}){
        try{
            return  await callApi('/admin/getShippingRulesById',arg);
        }catch(e){
            console.warn(e);
            return []
        }
    }
    async deleteShippingRules(arg={}){
        try{
            return  await callApi('/admin/deleteShippingRules',arg);
        }catch(e){
            console.warn(e);
            return []
        }
    }
    async AddShippingRules(arg={}){
        try{
            return  await callApi('/admin/AddShippingRules',arg);
        }catch(e){
            console.warn(e);
            return []
        }
    }
    async EditShippingRules(arg={}){
        try{
            return  await callApi('/admin/EditShippingRules',arg);
        }catch(e){
            console.warn(e);
            return []
        }
    }

    async getCareerManagement(arg={}){
        try{
            return  await callApi('/admin/getCareerManagement',arg);
        }catch(e){
            console.warn(e);
            return []
        }
    }
    async editDomesticInternation(arg={}){
        try{
            return  await callApi('/admin/editDomesticInternation',arg);
        }catch(e){
            console.warn(e);
            return []
        }
    }
    async editCareerFreeShipping(arg={}){
        try{
            return  await callApi('/admin/editCareerFreeShipping',arg);
        }catch(e){
            console.warn(e);
            return []
        }
    }

     async changeCategoryQty(arg={}){
        try{
            return  await callApi('/admin/changeCategoryQty',arg);
        }catch(e){
            console.warn(e);
            return []
        }
    }

     async scraping(arg={}){
        try{
            return  await callApi('/admin/scraping',arg);
        }catch(e){
            console.warn(e);
            return []
        }
    }

}
let Instance = Admin.getInstance();
export default Instance;
