import { Button, Form, Input, Switch, Result } from 'antd';
import authObj from '../../utility/authclass'
import { useDispatch } from 'react-redux';
import { int } from '../../redux/action/userAction';
import { useNavigate , Link} from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/index';
const ReturnPolicy = (props) => {
    
    const page_title = {
        fontSize: "24px",
        lineHeight: "30px",
        fontWeight: "700",
        boxShadow: "0 -1px #eee inset",
        textTransform: "capitalize"
    }
    const page_title_wrapper = {
        display: "inline-block",
        padding: "5px 0 7px",
        borderBottom: "3px solid #01adeb"
    }
    const container_top = {
       
        fontFamily: "Arial",
      
    };
    const box_half ={
      width: "50%",
      padding: "0 15px"
    }
    const box_layout = {
        position: "relative",
        display: "flex",
        flexWrap: "wrap",
        margin: "0 -15px"
    }

    return (<>
        <Header></Header>
        <div className="container"  style={container_top}>   
            <div class="page-title-wrapper"><h1 class="page-title" style={page_title}><span class="base" data-ui-id="page-title-wrapper" style={page_title_wrapper}>Return Policy</span></h1></div>
            <p><strong>IT Superstore wants you to be completely satisfied with the purchase of our products. In the event that an item needs to be returned for credit or replacement, please read the following guidelines before shipping the item back to our warehouse.</strong></p>
            <ul> 
                <li>IT Superstore offers customers a 30-day replacement warranty on all parts.</li>
                 <li>All Products come with a seven day DOA (Dead on Arrival) Warranty that covers a full refund including shipping.</li>
                 <li>All good part returns are subject to a 20% restocking fee and freight charges will not be refunded.</li>
                 <li>All RMA’s must be approved. Once items have been received and tested a refund or replacement will be shipped via ground delivery.</li>
                 <li>Any item returned without a pre-approved RMA will not be accepted.</li>
                 <li>All open RMA’s become void if not completed within 30 days of issuance.</li> 
                 <li>Please note that some parts may not be available for a replacement. In the event this may occur, a credit will be issued.</li> 
                 <li>A valid serial number must be in order to qualify for an RMA.</li> 
                 <li>All return packages must be clearly marked with the issued RMA number.</li> 
            </ul>
        </div>   
        <Footer></Footer>


      

    </>)
}
export default ReturnPolicy