import config from '../config/index'
import common from '../utility/commonclass';
import { Link } from 'react-router-dom';
import loginservice from '../services/login.service.js'
import {update} from '../services/customer.server'
import { notification } from 'antd';

class Auth {
    static myInstance = null;
    static userData = null;
    static token = null;


    static getInstance() {
        if (Auth.myInstance == null) {
            Auth.myInstance = new Auth();
        }

        return this.myInstance;
    }

    setData(data = {}) {
        this.userData = data.user;
        this.token = data.token;
    }

    getUserData(){
        const userdata=this.getUserSession();
        if(userdata){
            return userdata.user
        }else{
            return {}
        }

    }
    loadData(state) {     
        try {
            const userData = this.getUserSession();           
            if (userData)
                return { ...userData }
            else {
                return { ...state }
            }

        } catch (e) {
            console.warn(e);
            return { ...state }
        }

    }


    setUserSession(session) {
        localStorage.setItem(config.sessionkey, btoa(JSON.stringify(session)));
    }

    updateRedirectpath(path){
        sessionStorage.setItem(config.pathKey, btoa(path));        
    }

    getRedirectpath(){
        const data = sessionStorage.getItem(config.pathKey);
        console.log(data);
        if(data=="" || data==null){
            return "";
        }else{
            return (atob(data) !== "") ? atob(data) : ""; 
        }
             
    }


    getUserSession() {
        const data = localStorage.getItem(config.sessionkey);
        return (common.isEmptyObject(data) === true) ? false : JSON.parse(atob(data));

    }
    updateUserSession(data){
        const userdata=  this.getUserSession();
        const newuserdata={...userdata,user:{...userdata.user,...data}}
        this.setUserSession(newuserdata);
    }

    getToken() {

        return this.token;
    }

    async getCurrentUser() {
        const userdata= this.getUserSession()        
        return await userdata.user;
    }
    getCurrentName() {
        if (this.userData) {
            return this.capitalizeFirstLetter(this.userData.firstName) + ' ' +this.capitalizeFirstLetter(this.userData.lastName);
        } else {
            return "";
        }

    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    isLogin() {
        return (this.token) ? true : false;

    }
    logout() {
        localStorage.setItem(config.sessionkey, btoa(JSON.stringify({})));
        localStorage.setItem(config.cartKey, JSON.stringify({}));

    }

    async login(values) {
        try {
            const res = await loginservice.getLogin(values);
            if (res.status == 0) {
                notification.error({ key: 'login', message: res.errormessage });
                return false;
            } else {
                const userdata = res.data;
                this.setUserSession(userdata);
                notification.success({ key: 'login', message: res.message });
                //this.loadData()
                return userdata;

            }
        } catch (e) {
            return false;
        }



    }

    async registration(values) {
        try {
            const res = await loginservice.registration(values);
            if (res.status == 0) {
                notification.error({ key: 'registration', message: res.errormessage });
                return false;
            } else {
               // const userdata = res.data;
                //this.setUserSession(userdata);
                notification.success({ key: 'registration', message: res.message });
                //this.loadData()
               
                return  res.data;

            }
        } catch (e) {
            return false;
        }



    }

    removeUserSession() {
        localStorage.removeItem(config.sessionkey);
    }
   

    openLoginBox() {


    }

    /**************** Customer*************/
    updateCustomer(id,data){

        const res= update(id,data);
        this.updateUserSession(data);
        return res;
    }


    /******************* Link***************/
    getLoginLink() {
        /*const loginbtn=()=>{
                this.openLoginBox();

        }*/
        return <Link to="/login" ><i className="fa fa-user"></i>Login</Link>

    }
    getRegisterLink() {
        return <Link to="/login" ><i className="fa fa-user"></i>Register</Link>
    }

    getProfileLink(child) {
        return <Link to="/customer/" >{child}</Link>
    }
    



}
let Instance = Auth.getInstance();
export default Instance;
