import COD  from './COD';
import PAYPAL from './PAYPAL';
import PAYPALPayFlow from './PAYPALPayFlow';
import CustomerObj from '../../utility/customerClass';
import { useNavigate , Link} from 'react-router-dom';
const Payment = (props) =>{
const navigate= useNavigate()
const order=props.orderFullDetal;
const address=props.address;
//console.log('Payment');
//console.log(order);

const updatepayment =async(data={})=>{
    console.log('update payment');
    let res;
    if(order.paymentId==1){
        const res=await CustomerObj.updateOrderPaymentStatus({"orderid" : order.id, "orderstatus" : 'placed',"paymentId":order.paymentId,paymentStatus:0,paymentDetail:""});
    }else  if(order.paymentId==2){
        let tmp={
            transactionid:"",
            status:"",
            create_time:"",
            rowdata:{}

        }

        let paymentStatus=0;
        let paymentDetail="";
        if(data?.status=='COMPLETED'){
            paymentStatus=1;

            tmp['transactionid']=data?.purchase_units[0].payments.captures[0].id;  
            tmp['status']=data.status;  
            tmp['create_time']=data.create_time;    
            tmp['rowdata']=data;     
        }else{

            tmp['status']=data?.status;  
            tmp['rowdata']=data;

        }
        paymentDetail=JSON.stringify(tmp);

        console.log(paymentDetail);
        const res=await CustomerObj.updateOrderPaymentStatus({"orderid" : order.id, "orderstatus" : 'placed',"paymentId":order.paymentId,paymentStatus:paymentStatus,paymentDetail:paymentDetail});
        
    }else  if(order.paymentId==3){
        console.log(data);
        let tmp={
            transactionid:"",
            status:"",
            create_time:"",
            rowdata:{}

        }

        let paymentStatus=0;
        let paymentDetail="";
        if(data?.status=='COMPLETED'){
            paymentStatus=1;

            tmp['transactionid']=data?.purchase_units[0].payments.captures[0].id;  
            tmp['status']=data.status;  
            tmp['create_time']=data.create_time;    
            tmp['rowdata']=data;     
        }else{

            tmp['status']=data?.status;  
            tmp['rowdata']=data;

        }
        paymentDetail=JSON.stringify(tmp);

        console.log(paymentDetail);
        const res=await CustomerObj.updateOrderPaymentStatus({"orderid" : order.id, "orderstatus" : 'placed',"paymentId":order.paymentId,paymentStatus:paymentStatus,paymentDetail:paymentDetail});
        
    }
  
   console.log(res);
   navigate("/thanks");
  
  }

    return (
        <div className="container">
            {order.paymentId==1 && <COD order={order} updatepayment={updatepayment}></COD>}
            {order.paymentId==2 && <PAYPAL order={order} updatepayment={updatepayment}></PAYPAL>}
    {order.paymentId==3 && <PAYPALPayFlow order={order} address={address} updatepayment={updatepayment}></PAYPALPayFlow> }

            
        </div>

    )

}
 export default Payment;