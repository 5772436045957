import React, {useState, useEffect} from 'react'

import "bootstrap/dist/css/bootstrap.min.css"
import {notification,Button,Form,message,Input} from 'antd';
import SeoObj from '../../../utility/adminClass'
import {useParams,useNavigate} from "react-router-dom";
const { TextArea } = Input;
export default function Edit(){
     const navigate = useNavigate()  
     const [userData,setUserData] = useState({});
     const [form] = Form.useForm(); 
     const {id} = useParams();
     useEffect(()=>{          
          async  function  getSeoData(){
               var vdata = {id:id}
               const data = await SeoObj.getSeo(vdata);
               setUserData({ ...data });
          }
          getSeoData();
     },[])
          
     
     const onFinish = async (values) => {
          values.id=id;
          const saveData = await SeoObj.getSeoEdit(values);
          if(saveData?.data?.length > 0){
               notification.success({message:'Seo Setting updated successfully'})
               setTimeout(() => {
                navigate('/admin/seoSetting')
            }, 1000)
          }else{
               notification.error({message:'Something error'})
          }          
     };
        
          return (<>
               {userData.metaTitle && <Form
                    name="basic"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={userData}
                    onFinish={onFinish}
                    autoComplete="off"
               >
                    <Form.Item
                        label = 'Meta Title'
                        name = 'metaTitle'
                        rules={[
                        {
                            required: true,
                            message: 'Please input your Meta Title',
                        },
                        ]}
                    >
                    <Input />
                    </Form.Item>
                    <Form.Item
                        label = 'Meta Keywords'
                        name = 'metaKeywords'
                        rules={[
                        {
                            required: true,
                            message: 'Please input your Meta Keywords',
                        },
                        ]}
                    >
                    <TextArea rows={2} />
                    </Form.Item>                   
                    <Form.Item
                        label = 'Meta Description'
                        name = 'metaDescription'
                        rules={[
                        {
                            required: true,
                            message: 'Please input your Meta Description',
                        },
                        ]}
                    >
                    <TextArea rows={4}  />
                    
                    </Form.Item>
                    
                    <Form.Item
                        wrapperCol={{
                        offset: 8,
                        span: 16,
                        }}
                    >
                        <Button type="primary" htmlType="submit">
                        Save
                        </Button>
                    </Form.Item>
                    </Form>}
          </>);
        }