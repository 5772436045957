import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from "react-router-dom"

import CartButton from '../Product/CartButton';
import productObj from '../../utility/productClass'
import commonObj from '../../utility/commonclass'
import cartobj from '../../utility/cartClass';
import { Empty } from 'antd';
import React, { useState, useEffect } from 'react';
import WishList from '../Product/Wishlist';
const options = {
    margin: 10,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: false,
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 3,
        },
        1000: {
            items: 4,

        }
    },
};

const CaptegoryProduct = function (props) {
    const [tab, setTab] = useState("newtab");
    const [productdata, setproductdata] = useState({ url: "", list: [] });
    const category = props.category;
    useEffect(() => {

        async function getData() {
            const data = await productObj.getProductData({ filter: { category: category.id }, limit: 10 });
            //console.log('product list');
            //console.log(data);
            //console.log(data.productdata.rows);
            let listdata = (commonObj.isEmpty(data?.productdata?.rows) == false) ? data?.productdata?.rows : []
            setproductdata({ url: data.url, list: listdata });
            //console.log(productdata);
        }

        getData();

    }, [])

    return (<>
        <div className="col-xxl-12 col-xl-12 col-lg-12">
            <div className="product__item-wrapper pt-15">
                <div className="product__top section__title-line d-sm-flex align-items-start mb-35">
                    <div className="section__title-wrapper mr-30">
                        <h3 className="section__title"><Link to={productObj.categoryUrl(category)}>{category.categoryName}</Link></h3>
                    </div>

                </div>
                <div className="product__tab-content">
                    <div className="tab-content" id="myTabContent">


                        {tab == 'newtab' && <div className="tab-pane fade show active" id="new" role="tabpanel" aria-labelledby="new-tab">
                            {productdata.list.length > 0 && <OwlCarousel className="product__item-slider common-nav owl-carousel" {...options} >
                                {productdata.list.map((item) => {
                                   // console.log(item?.id);
                                    return (<div className="product__item-single" key={item?.id} title={item?.productName}>
                                        <div className="product__item mb-20">
                                            <div className="product__thumb w-img fix">
                                                <Link to={`/product/${item?.productSku}`}>
                                                    {productObj.productImage(item, productdata.url)}

                                                </Link>
                                                <div className="product__action transition-3">
                                                    <ul>
                                                        <li>
                                                            <CartButton product={{ ...item, productImage: ((item.productImage) ? productdata.url + item.productImage : "") }} classes="select-btn w-100"> 
                                                            <svg viewBox="0 0 22 22">
                                                                <g>
                                                                    <path d="M18,19H6c-0.5,0-0.92-0.37-0.99-0.86L3.13,5H1C0.45,5,0,4.55,0,4s0.45-1,1-1h3c0.5,0,0.92,0.37,0.99,0.86L6.87,17h10.39
                                                                                l2.4-8H11c-0.55,0-1-0.45-1-1s0.45-1,1-1h10c0.32,0,0.61,0.15,0.8,0.4c0.19,0.25,0.25,0.58,0.16,0.88l-3,10
                                                                                C18.83,18.71,18.44,19,18,19z" />
                                                                </g>
                                                            </svg>
                                                            </CartButton>
                                                        </li>

                                                        <li>
                                                           
                                                                {

                                                                    <WishList id={item.id} />
                                                                }
                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="product__content text-center">
                                                <div className="product__tag product__tag-4">
                                                    <span className="sku-box"> SKU : <Link to={`/product/` + item.productSku}>{productObj.displaySKU(item)}</Link></span>
                                                </div>
                                                {cartobj.displayCondition(item)}

                                                <h3 className="product__title">
                                                    {productObj.getProductName(item, true)}

                                                </h3>
                                                {/*<div className="product__price product__price-4 mb-10">
                                                                {productObj.ProductPrice(item) > 0 && <div className="price-box">
                                                                    <span className="price"><ins>{productObj.PriceDisplay(item)}</ins></span>
                                                                    {commonObj.isEmpty(cartobj.ProductCrossPrice(item))==false && <del>{cartobj.CrossPriceDisplay(item)}</del>}
                                                                     </div>
                                                
                                                }

                                                                    
                                                                    {cartobj.displayQuantity(item)}
                                                                   
                                                                </div>
                                            */}
                                            {productObj.displayPriceQuantity(item)}

                                            </div>
                                        </div>
                                    </div>
                                    )

                                })}
                            </OwlCarousel>
                            }
                            {productdata.list.length == 0 && <Empty />}
                        </div>
                        }


                    </div>
                </div>
            </div>
        </div>
    </>)

}

export default CaptegoryProduct;