import {React} from 'react';
import {Link} from 'react-router-dom'
export default function MenuComponent(){

    let Menu=[
           
            {
                label:'Setting Management',
                link:'',
                
                submenu:[
                    {
                        label:'Social Management',
                        link:'social',

                    },
                    {
                        label:'Seo Management',
                        link:'seoSetting',

                    },
                    /*{
                        label:'Scrape Management',
                        link:'scrapeSetting',

                    }*/
                ]
            },
            {
                label:'Category Management',
                link:'category',
                submenu:[]
            },

            {
                label:'Shipping Management',
                link:'',
                
                submenu:[
                    {
                        label:'Shipping Rules',
                        link:'shippingRules',

                    },
                    {
                        label:'Carrier Management',
                        link:'careerManagement',

                    }
                ]
            }
    ]
    
  let menutoggle= (event,key)=>{     
    event.preventDefault();    
    if(event.target.parentElement.classList.contains('show')){        
        event.target.parentElement.classList.remove('show');
    }else{
        event.target.parentElement.classList.add('show');
    }
   
    /*Menu[key].showmenu=true;
    console.log(Menu[key]);
    console.log('menu click');*/

  }
return (
    
    <>
      <div className="sidebar sidebar-dark sidebar-fixed" id="sidebar">
      <div className="sidebar-brand d-none d-md-flex" style={{padding:'10px'}}>
      	<img src={process.env.PUBLIC_URL+'/images/logo.png'} width="70px" alt="logo" />
        
      </div>
      <ul className="sidebar-nav" data-coreui="navigation" data-simplebar="">
            {
             Menu.map( (item,itemkey) => {
                return (                    
                        <li key={item.label} className={(item?.submenu?.length > 0 && item.showmenu===true)?'nav-group show':(item?.submenu?.length > 0)?'nav-group':'nav-item'}>
                            
                        {
                        (item?.submenu?.length > 0) && 
                        <>
                            <a className="nav-link nav-group-toggle" href="false" onClick={(e)=>menutoggle(e,itemkey)}>
                            <span className="nav-icon"></span>{item.label}</a>
                            <ul className="nav-group-items">
                                {
                                    item.submenu.map((subitem,subitemkey)=>{
                                        return  <li className="nav-item" key={subitemkey}><Link className={'nav-link'} to={subitem.link}> <span className="nav-icon"></span>{subitem.label}</Link></li>

                                    })
                                }
                                   
                            </ul>
                        </>

                        }

                        {(item?.submenu?.length === 0) &&                         
                            <Link className={'nav-link'} to={item.link}> <span className="nav-icon"></span>{item.label}</Link>
                       

                        }
                        
                        </li> 
                    
                

                )

                })   
            }
      </ul>
      
    </div>

    </>
)

}
