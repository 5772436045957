let Footer=()=>{
let copy = new Date().getFullYear();
return (
    <footer className="footer">
        <div><a href="">ItSuperSTore</a> © {copy} .</div>       
    </footer>

)

}

export default Footer; 