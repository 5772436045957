import config from '../config/index'
import common from '../utility/commonclass';
import { useParams, Link } from 'react-router-dom';
import commonService from '../services/common.service'
class Cart {

    static myInstance = null;
    curreny = '$';
    cartData = {}
    constructor() {

    }
    static getInstance() {
        if (Cart.myInstance == null) {
            Cart.myInstance = new Cart();
        }

        return this.myInstance;
    }
    setCartData(data) {
        this.cartData = data;
    }
    getCartCount() {

        return Object.keys(this.cartData).length;
    }

    cartTotal() {

        let cartAmount = Object.keys(this.cartData).reduce((pre, current) => {
            let product = this.cartData[current].product;
            let price = (product.productPrice && !isNaN(product.productPrice)) ? product.productPrice : 0;
            let qty = (this.cartData[current].cartQty) ? this.cartData[current].cartQty : 1;
            console.log(price + '--' + qty)
            pre += parseFloat(price) * parseFloat(qty);
            return pre;
        }, 0);
        return isNaN(cartAmount) ? 0 : cartAmount;

    }

    loadCart(state, action) {

        return this.AddtoCart(state, action);

    }

    AddtoCart(state, action) {
        try {
            const cartData = this.getCartLocalStorage();
            return { ...cartData }

        } catch (e) {
            console.warn(e);
            return { ...state }
        }

        return { ...state }
    }


    getCartLocalStorage() {
        try {
            const serialisedState = localStorage.getItem(config.cartKey);
            if (serialisedState === null) return {};
            return JSON.parse(serialisedState);
        } catch (e) {
            console.warn(e);
            return {};
        }

    }

    saveCartLocalStorage(product, qty = 1) {


        try {
            const cartData = this.getCartLocalStorage();
            if (cartData[product.id]) {
                cartData[product.id].cartQty = cartData[product.id].cartQty + qty;
            } else {
                cartData[product.id] = { product: { ...product }, cartQty: qty }
            }
            localStorage.setItem(config.cartKey, JSON.stringify(cartData));
            return true;
        } catch (e) {
            console.warn(e);
            return false;
        }

    }

    updateCartLocalStorageQty(product, qty = 1) {

        try {
            const cartData = this.getCartLocalStorage();
            if (cartData[product.id]) {
                cartData[product.id].cartQty = qty;
            }
            localStorage.setItem(config.cartKey, JSON.stringify(cartData));
            return true;
        } catch (e) {
            console.warn(e);
            return false;
        }

    }

    deleteCartLocalStorage(product) {

        try {
            const cartData = this.getCartLocalStorage();
            delete cartData[product.id];
            localStorage.setItem(config.cartKey, JSON.stringify(cartData));
            return true;
        } catch (e) {
            console.warn(e);
            return false;
        }

    }

    emptyCartLocalStorage() {

        try {
            localStorage.setItem(config.cartKey, JSON.stringify({}));
            return true;
        } catch (e) {
            console.warn(e);
            return false;
        }

    }

    getProductImage(cartItem = {}) {
        if (cartItem.product && cartItem.product.productImage) {
            return <img src={cartItem.product.productImage} alt="" />
        } else {
            return <img src={require("../images/product.png")} alt="" />
        }

    }

    ProductPrice(product) {
        return product.productPrice;
    }
    ProductCrossPrice(product) {
        return product.productCrossPrice;
    }

    PriceDisplay(product) {
        return this.curreny + this.ProductPrice(product);
    }

    CrossPriceDisplay(product) {
        return this.curreny + this.ProductCrossPrice(product);
    }

    cartItemTotal(cartItem) {
        return this.ProductPrice(cartItem.product) * cartItem.cartQty;
    }

    cartItemTotalDisplay(cartItem) {

        return this.curreny + this.cartItemTotal(cartItem);

    }
    cartGrossTotal() {
        const cartData = this.getCartLocalStorage();
        let total = 0;
        if (cartData) {
            total = Object.keys(cartData).reduce((pre, current) => {
                pre = pre + this.cartItemTotal(cartData[current]);
                return pre;
            }, 0)
        }


        return total;

    }

    cartGrossTotalDisplay() {
        const total = this.cartGrossTotal();
        return (total) ? this.curreny + total : "";

    }

    cartTaxDisplay() {
        const total = this.cartTax();
        return (total) ? this.curreny + total : "";

    }
    cartTax() {
        let tax=0;
        const total = this.cartGrossTotal();
        if(total > 0){
            tax= total*7/100;
        }
        return tax;

    }

    
    async checkforfreeshiping(address = {}) {
        console.log("checkforfreeshiping");
        console.log(address);
        if(Object.keys(address).length == 0) {
            return false;
        }else if(address?.countryId != 1){
            console.log("othetr country")
            return false;
        }else if(address?.countryId == 1){
            console.log("Us country")

         let datares=  await commonService.CheckFreeshiping(address);
            console.log(datares);
            if(datares==0){
                return true;
            }else{
                return false;
            }
            
        }


    }
    

    async calculateShiping(address = {},method="GROUND_HOME_DELIVERY") {
        if (Object.keys(address).length == 0) {
            return false;
        }
        console.log('calculateShiping');
        console.log(address);
        // const address=(Object.keys(this.address).length > 0)?this.address:{};
        const ZipTo= address.pincode;
        //const ZipTo = '35233';
         const CountryCgTo= address?.country?.sortname;
        //const CountryCgTo = "US";
        const CountryCgFrom = "US";
        const ZipFrom = "32746";
        console.log(this.cartData);

        let categoryWiseqty={}
        for(let i in this.cartData){
            let catid=this.cartData[i]?.product?.productCategory;   
            if(categoryWiseqty[catid]==null || categoryWiseqty[catid]==undefined){
                categoryWiseqty[catid]={qty:0,detail:{
                    'Weight': 1,
                    'wtUnit': 'LB',
                    'htUnit': 'IN',
                    'Length': '1',
                    'Width': '1',
                    'Width': '1',
                }}
            }
            categoryWiseqty[catid]['qty'] =categoryWiseqty[catid]['qty']+this.cartData[i].cartQty;

                
        }
        console.log("cattttt",categoryWiseqty);
        let total=0;
        let currency="USD";
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url="https://eznetcrm.com/erp/admin/api/Rate/fedex.php";

    for(let i in categoryWiseqty){
        let catdetail=await commonService.callApi('/product/getCategoryName',{isCategory:i});
        console.log("catdetail",catdetail);
        let categoryQty=categoryWiseqty[i]?.qty;
        if(catdetail?.status==1 && catdetail?.data?.categoryQty){
            let limitcat=catdetail?.data?.categoryQty;
            categoryQty=  Math.ceil(categoryWiseqty[i]?.qty/limitcat); 
        }
        let shipItems=[categoryWiseqty[i]?.detail];
        const data = await commonService.callApi(url, { ZipFrom: ZipFrom, ZipTo: ZipTo, CountryCgFrom: CountryCgFrom, CountryCgTo: CountryCgTo, item: shipItems,method:method });

        if (data?.status == 1) {
            total =total + (+data.amount*categoryQty);
            currency=data?.currency;
            //this.ShippingData = { amount: data.amount, currency: data.Currency,status:"1" }
        }

        console.log("applyqty",categoryQty);


    }
    console.log("totalship",total);
    this.ShippingData = { amount: total.toFixed(2), currency: currency,status:"1" }
        return  this.ShippingData;

 /*

    let shipItems=[];
        for(let i in this.cartData){
            console.log("loop");
            console.log(this.cartData[i].cartQty);
            console.log("categoryid",this.cartData[i]?.product?.productCategory);
            
            let catdetail=await commonService.callApi('/product/getCategoryName',{isCategory:this.cartData[i]?.product?.productCategory});
            console.log("catdetail");   
            console.log(catdetail);
            let categoryQty=0;
            if(catdetail?.status==1 && catdetail?.data?.categoryQty){
                categoryQty=catdetail?.data?.categoryQty;
                
            } 
            console.log("categoryQty",categoryQty);

            for(let j=1;j<=this.cartData[i].cartQty;j++){
            shipItems.push({
                            'Weight': 1,
                            'wtUnit': 'LB',
                            'htUnit': 'IN',
                            'Length': '1',
                            'Width': '1',
                            'Width': '1',
                        });
                    if(categoryQty > 0 && j==categoryQty){
                        break;
                    }

            }


        }
        console.log("shipItems");
        console.log(shipItems);
        //return false;
       let Item = Object.keys(this.cartData).map((item) => {
            console.log(this.cartData[item]);

            return {
                'Weight': 1,
                'wtUnit': 'LB',
                'htUnit': 'IN',
                'Length': '1',
                'Width': '1',
                'Width': '1',
            }
        })*/

        /*   $Weight = $value['Weight'];
           $wtUnit = $value['wtUnit'];
           $htUnit = $value['htUnit'];
           $Length = $value['Length'];
           $Width =  $value['Width'];
           $Height = $value['Height'];*/

      /*  const headers = {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url="https://eznetcrm.com/erp/admin/api/Rate/fedex.php";
        //url="http://localhost/eznetcrm/erp/admin/api/Rate/fedex.php";
      
        const data = await commonService.callApi(url, { ZipFrom: ZipFrom, ZipTo: ZipTo, CountryCgFrom: CountryCgFrom, CountryCgTo: CountryCgTo, item: shipItems,method:method });

        if (data?.status == 1) {
            this.ShippingData = { amount: data.amount, currency: data.Currency,status:"1" }
        } else {
            this.ShippingData = {message:data.message,status:"0"}
        }
        return this.ShippingData;*/

    }

    shippingTotal() {

        return (this.ShippingData?.amount > 0) ? this.ShippingData?.amount : 0;

    }
    shippingTotalDisplay(arg = true) {
        if (arg === false) {
            return '--';
        }
        console.log('shippingTotalDisplay');
        console.log(this.shippingTotal());
        if (!this.shippingTotal()) {
            return 'Free Shipping';
        } else {
            return this.curreny + this.shippingTotal();
        }


    }

    cartTotal() {
        let total = 0;
        if (!isNaN(this.cartGrossTotal())) {
            total += parseFloat(this.cartGrossTotal());
        }
        if (!isNaN(this.shippingTotal())) {
            total += parseFloat(this.shippingTotal());
        }

        if (!isNaN(this.cartTax())) {
            total += parseFloat(this.cartTax());
        }

        console.log('cart-total'+total);
        return total;
    }

    cartTotalDisplay() {
        const total = this.cartTotal();
        console.log(this.curreny);

        return this.curreny + common.numberFormat(total);

    }

    getCartLink(label = 'My Cart') {
        return <Link to="/cart" className="btn">{label}</Link>
    }

    getProductVariant(cart) {

        if (cart.product.isVariant === 1) {
            return (<ul className="cart-variant-list">
                {
                    cart.product.varintDetail.map((item, itemKey) => {

                        return (<li>{`${item.attributeName} : ${item.attributeOptionName}`}</li>)
                    })
                }

            </ul>);
        }
        return '';

    }
    getCondition(item){
        if (item?.productrefType == 'main-condition' || item?.productrefType=='alias-condition') {
        if(item?.productSku.includes("certified-refurbished")==true){
            return "Certified Refurbished";
        }else if(item?.productSku.includes("third-party")==true){
            return "Third Party";
        }else if(item?.productSku.includes("used-no-hologram")==true){
            return "Used No Hologram";
        }else if(item?.productSku.includes("new-0-hrs")==true){
            return "New 0 Hrs";
        }else if(item?.productSku.includes("new-bulk")==true){
            return "New Bulk";
        }else if(item?.productSku.includes("new-sealed-spares")==true){
            return "New Sealed Spares";
        }else if(item?.productSku.includes("blank-trays")==true){
            return "Blank Trays";
        }else if(item?.productSku.includes("new-retail")==true){
            return  "New Retail";
        }else if(item?.productSku.includes("new-open-box")==true){
            return  "New Open Box";
        }else if(item?.productSku.includes("hp-renew")==true){
            return  "HP Renew";
        }else if(item?.productSku.includes("no-tray")==true){
            return  "No Tray";
        }else if(item?.productSku.includes("used")==true){
            return "Used";
        }else{
            return "";
        }
    }else{
        return "";
    }
        
        
    }

    displayCondition(item) {
        
        if (item?.productrefType == 'main-condition' || item?.productrefType == 'alias-condition') {
            let condition=this.getCondition(item);
            return (<div className="product__tag product__tag-4">
                <span className="sku-box"> Condition : {condition}</span>
            </div>)
        } else {
            return ""
        }

    }

    displayQuantity(item){
        if (item?.productQuantity  > 0) {
            return ( <div className="qty-box">
            <span className=""> <strong>Qty</strong>   {item?.productQuantity}</span>
            </div>)
        } else {
            return ""
        }
    }


}
let Instance = Cart.getInstance();
export default Instance;