import React, {useState, useEffect} from 'react'
import "bootstrap/dist/css/bootstrap.min.css"
import {Button,EditOutlined,Pagination,notification} from 'antd';
import SeoObj from '../../../utility/adminClass'
import {Link,} from "react-router-dom";
  
export default function Scrape(){
     const [seolist,setList]=useState({list:[],count:0}); 
     const [paging, setpage] = useState({ currentpage: 1, itemlimit: 20 });
     //const [filter, setFilter] = useState({  });  
     /*********Token send***********/  
     let token = sessionStorage.getItem('_adminitstore');
     /**********End**********/
   
     useEffect(()=>{          
          async  function  getSeoListData(){
               const data =await SeoObj.getSeoPagination({ page: paging.currentpage, limit: paging.itemlimit},{headers: {"Authorization" : `Bearer ${token}`} });
               console.log(data);
               setList({list:data.rows,count:data.count});
          }
          getSeoListData();
     },[paging])


const scraping=async(e)=>{

 const data =await SeoObj.scraping();

 notification.success({placement:"bottomRight",key:"notf",message:"Scraping process is in progress . It will take 10-15 minutes"})

}

     const handlePageChange = (page) => {
          setpage((pre) => {
              return { ...pre, currentpage: page }
          })
     } 
     
    
          return (
               <div style={{textAlign:"center"}}>
                 
                     <Button type="primary" onClick={(e)=>scraping(e)}>Scrape</Button>
               </div>  
          )   
}
