
import { Outlet } from 'react-router-dom'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/index'
import Menu from '../components/Customer/Menu'
export default function DashboardLayout() {


    return (
        <>

            <Header></Header>
            <div className="body flex-grow-1 px-3 ">
                <div className="container-lg customer-container">
                    <div className="row">
                        <div className="col-md-3">
                            <Menu></Menu>
                        </div>
                        <div className="col-md-9">
                        <div className="admin_dashboard_panel">    
                            <Outlet></Outlet>
                        </div>
                        </div>
                    </div>

                </div>
            </div>
            <Footer></Footer>



        </>

    )

}