import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


const HomeSlider = function (props) {

    return (<>

        <section className="slider__area">
            <div className="slider__active swiper-container">
                <div className="swiper-wrapper">
                    <OwlCarousel
                        navContainerClass="main-slider-pagination"
                        dots={false}
                        className='owl-theme product-carousel slider__title' id="bxslider-home4" loop nav items={1} >
                        <div className="slider__item slider__height swiper-slide d-flex align-items-center include-bg" style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL +'/images/banner.png'})`
                        }}>
                            {/*<div className="container">
                                <div className="row">
                                    <div className="col-xxl-5">
                                        <div className="slider__content p-relative z-index-11">
                                            <span data-animation="fadeInUp" data-delay=".3s">SUMMER ‘21</span>
                                            <h3 className="slider__title" data-animation="fadeInUp" data-delay=".5s">Living Luxury</h3>
                                            <div className="slider__btn" data-animation="fadeInUp" data-delay=".4s">
                                                <a href="#" className="slider-btn">Discover Now <i className="fal fa-angle-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    </div>*/}
                        </div>
                        <div className="slider__item slider__height swiper-slide d-flex align-items-center include-bg" style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL +'/images/banner2.png'})`
                        }}>
                          {/*  <div className="container">
                                <div className="row">
                                    <div className="col-xxl-5">
                                        <div className="slider__content p-relative z-index-11">
                                            <span data-animation="fadeInUp" data-delay=".3s">SUMMER ‘21</span>
                                            <h3 className="slider__title" data-animation="fadeInUp" data-delay=".5s">Living Luxury</h3>
                                            <div className="slider__btn" data-animation="fadeInUp" data-delay=".4s">
                                                <a href="#" className="slider-btn">Discover Now <i className="fal fa-angle-right"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>*/}
                        </div>
                    </OwlCarousel>
                </div>
            </div>
        </section>
    </>)

}
export default HomeSlider;