import axios from '../config/axios';
import { notification } from 'antd';

function getResponseHandler(response,restype='array') {

    if (response.data.status === 1) {
        return response.data.data;
    } else {
        notification.error({ message: response.data.errormessage });
        if(restype=='array'){
            return [];
        }else{
            return {};
        }
       
    }

}


export const callApi= async (url,data,restype='array') => {
    try {
        const rowData = await axios.post(url, data);
        return getResponseHandler(rowData,restype);


    } catch (error) {
        console.error(error);
        notification.error({ message: error.message });

    }


}


