 const SET_DATA='GET_CART';
 const EMPTY_DATA='EMPTY_DATA';
 const UPDATE_DATA='UPDATE_DATA';
 /*const UPDATE_CART
 const DELETE_CART;
 const DECREASE_QUANTITY;
 const INCREASE_QUANTITY;
 const EMPTY_CART;*/
export {SET_DATA,EMPTY_DATA,UPDATE_DATA}


export function int(){

    return{
        type:SET_DATA  
    }

}
export function updateUserRedux(data){

    return{
        type:UPDATE_DATA,
        payload:data  
    }

}

export function emptySession(){

    return{
        type:EMPTY_DATA  
    }
}