 const GET_CART='GET_CART';
 const ADD_CART='ADD_CART';
 const LOAD_CART='LOAD_CART';
 const EMPTY_CART='EMPTY_CART'
 /*const UPDATE_CART
 const EMPTY_CART;
 const DECREASE_QUANTITY;
 const INCREASE_QUANTITY;
 const EMPTY_CART;*/
export {GET_CART,ADD_CART,LOAD_CART,EMPTY_CART}
export function getCartProducts(){
    return{
        type:GET_CART
    }
}

export function addToCartAction(product){
    console.log('call Addto Cart');
    return{
        type:ADD_CART,
        payload:product

    }
}

export function loadCartInt(){

    return{
        type:LOAD_CART,        

    }

}

export function emptyCart(){
    return{
        type:EMPTY_CART        

    }
}