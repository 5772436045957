import { useState, useEffect } from "react";
import { Form, Input,  Button, notification, Col,Select,Spin } from 'antd';
import ShippingObj from '../../../utility/adminClass'
import commonObj from '../../../utility/commonclass'
import { useParams,useNavigate } from 'react-router-dom';
const { Option } = Select;
const Edit = () => {
    const navigate=useNavigate();
    const [shippingData, setShipping] = useState({});
    const {id} =useParams();   
    const [form] = Form.useForm(); 
    const [ rateType, setRateType ] = useState("");
    useEffect(()=>{
          async function getData(){
          const shippingResults =  await ShippingObj.getShippingRulesById({id:id});   
          if(shippingResults.type=='Custom'){
              setRateType('Custom');
          }
          setShipping(shippingResults);
        }
        getData();


    },[])
    const onFinish = async (values) => {
     
        try { 
           let Edit = await ShippingObj.EditShippingRules({...values,id:shippingData.id}); 
           navigate('/admin/shippingRules');
           notification.success({key:'save',message:'Shipping Rules has been updated successfully'})
        } catch (e) {

        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


   
    return (<>
        <div className="address-box">
           
        {shippingData.type && <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
            initialValues={shippingData}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            form={form}
        >
            

            <Form.Item
                label="Country"
            >
                <b>{shippingData.country.name}</b>
            </Form.Item>

            <Form.Item
                label="State"
            >
               <b>{shippingData.state.name}</b>
            </Form.Item>
               
                <Form.Item
                    name="type"
                    label="Type"
                    rules={[
                        {
                            required: true,
                            message: 'Please Select Type',
                        },
                    ]}
                >

                    <Select
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        onChange={value => setRateType(value)}         
                    >
                    <Option value="Fedex-Api" key="Fedex-Api">Fedex-Api</Option>
                    <Option value="Exclude" key="Exclude">Exclude</Option>         
                    <Option value="Custom" key="Custom">Custom</Option>                               
                    </Select>
                 </Form.Item>



                 {rateType === 'Custom' && (
                <Form.Item
                    name="rate"
                    label="Rate"
                >

                    <Input className={"form-control"} placeholder="Rate" />
                </Form.Item>
                 )}
                <Form.Item
                        wrapperCol={{
                        offset: 8,
                        span: 16,
                        }}
                    >
                        <Button type="primary" htmlType="submit" style={{width:"15%"}}>
                        Submit
                        </Button>
                    </Form.Item>

            </Form>}

        </div>


    </>)

}
export default Edit;
