import { Button, Form, Input, Switch,notification } from 'antd';
import authObj from '../../utility/authclass'
import { useDispatch } from 'react-redux';
import { int } from '../../redux/action/userAction';
import { useNavigate , Link} from 'react-router-dom';
import { useEffect, useState,useRef } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import config from '../../config/index'
const Login = (props) => {
     const recaptcha = useRef();
    const dispatch = useDispatch();
    const navigate= useNavigate();
    //const [list,setlist]=useState({list:[],count:0,ll:0})  
    //setlist((pre)=>({...pre,ll:3}));
    const componentSize = 'large';
    const loginkey = 'login';
    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])
    const onFinish = async (values) => {
     let captchaValue = recaptcha.current.getValue();
      //let captchaValue=1;
        if (!captchaValue) {
            notification.error({'key':'1','message':'Please verify the captcha','plcaement':'bottomRight'});
          //alert("Please verify the reCAPTCHA!");
        } else {
        const userData = await authObj.login(values);
        console.log(userData);
        if (userData !== false) {
           authObj.setData(userData)
            dispatch(int());
          const path= await authObj.getRedirectpath();
         
           if(path!==''){
                navigate(path);
            }else{
                navigate("/")
            }
            
        }
    }

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    return (<>
        <div className="loginBox">
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
              
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Username!',
                        },
                    ]}
                >
                    { /*prefix={<UserOutlined className="site-form-item-icon" /> */}
                    <Input className={"form-control"} placeholder="Username" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Password!',
                        },
                    ]}
                >
                    { /*prefix={<UserOutlined className="site-form-item-icon" /> */}
                    <Input className={"form-control"} placeholder="Password" />
                </Form.Item>
                 <ReCAPTCHA ref={recaptcha} sitekey={config.captchaSiteKey} />
               
                <div className="row">
                    <div className="col-12">
                        <div className="creat_account">
                        <Button type="primary" htmlType="submit" className="login-form-button">Login Now
                        </Button>
                        </div>
                    </div>
                    <div className="col-6 text-center"> 
                    <Link to="/forgot-password" className='text-center'>
                        <button className="btn btn-link px-0" type="button">Forgot password?</button>
                    </Link>
                    </div>
                    <div className="col-6 text-center">
                        <Link  to="/registration" className='text-center'>
                        <button className="btn btn-link px-0" type="button">Create an Account</button>
                        </Link>
                </div>
                </div>
                
            </Form>
        </div>

    </>)
}
export default Login