

import customerObj from '../../utility/customerClass'
import React, { useState, useEffect } from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css'
import authObj from '../../utility/authclass';
import { InputNumber, notification, Divider } from 'antd';
import { useNavigate, Link, useSearchParams } from "react-router-dom";
const Wishlist = (props) => {
    const[wishlist,setwishlist] = useState([]);
    let Wish  = [];
    let navigate = useNavigate();
    async function wishData() {
        const userData = await authObj.getCurrentUser();
        if(userData?.id){    
            const allgetWishlist=  await customerObj.getWishlist();
            const doubleValue = allgetWishlist.list.map((wishdata)=>{   
                Wish.push(wishdata.productId);
            }); 
            setwishlist(Wish);
        }
       }
       const handleWishlist = async (action, pid) => {  
        console.log('satish wish112');  
        const userData = await authObj.getCurrentUser();
        if(!userData?.id){
            //console.log('customer');
            navigate('/login')
        }else{      
            console.log('satish wish');      
            let wishlistdata = {action:action,productId:pid,customerId:userData.id}
            let wishlistData = await customerObj.addorRemoveWishlist(wishlistdata);
            
            if(wishlistData?.data?.length > 0){
                notification.success({message:'Wishlist Added Successfully..'});
            }
            wishData(); 
        }      
      };

    useEffect(() => {        
       wishData();
    },[]);

    
    const wishdExist = wishlist.find(element => {
        if (element == props.id) {
            return true;
        }else{             
            return false;
        }
    });

    return (<>
           {
               
            (wishdExist) ? <a onClick={event => handleWishlist('remove', props.id)}>
                <svg data-name="Layer 1" viewBox="0 0 122.88 107.39">
                    <path d="M60.83 17.18c8-8.35 13.62-15.57 26-17 23.17-2.64 44.44 21.08 32.74 44.43-3.33 6.65-10.11 14.56-17.61 22.32-8.23 8.52-17.34 16.87-23.72 23.2l-17.4 17.26-14.38-13.84C29.16 76.89 1 55.92 0 29.94-.63 11.74 13.73.08 30.25.29c14.76.2 21 7.54 30.58 16.89Z" style={{fill:'#ed1b24',fillRule:'evenodd'}}/>
                </svg>
                </a>:<a onClick={event => handleWishlist('add', props.id)}>
                    <svg viewBox="0 0 22 22">
                        <path d="M20.26,11.3c2.31-2.36,2.31-6.18-0.02-8.53C19.11,1.63,17.6,1,16,1c0,0,0,0,0,0c-1.57,0-3.05,0.61-4.18,1.71c0,0,0,0,0,0 L11,3.41l-0.81-0.69c0,0,0,0,0,0C9.06,1.61,7.58,1,6,1C4.4,1,2.89,1.63,1.75,2.77c-2.33,2.35-2.33,6.17-0.02,8.53 c0,0,0,0.01,0.01,0.01l0.01,0.01c0,0,0,0,0,0c0,0,0,0,0,0L11,20.94l9.25-9.62c0,0,0,0,0,0c0,0,0,0,0,0L20.26,11.3 C20.26,11.31,20.26,11.3,20.26,11.3z M3.19,9.92C3.18,9.92,3.18,9.92,3.19,9.92C3.18,9.92,3.18,9.91,3.18,9.91 c-1.57-1.58-1.57-4.15,0-5.73C3.93,3.42,4.93,3,6,3c1.07,0,2.07,0.42,2.83,1.18C8.84,4.19,8.85,4.2,8.86,4.21 c0.01,0.01,0.01,0.02,0.03,0.03l1.46,1.25c0.07,0.06,0.14,0.09,0.22,0.13c0.01,0,0.01,0.01,0.02,0.01c0.13,0.06,0.27,0.1,0.41,0.1 c0.08,0,0.16-0.03,0.25-0.05c0.03-0.01,0.07-0.01,0.1-0.02c0.07-0.03,0.13-0.07,0.2-0.11c0.03-0.02,0.07-0.03,0.1-0.06l1.46-1.24 c0.01-0.01,0.02-0.02,0.03-0.03c0.01-0.01,0.03-0.01,0.04-0.02C13.93,3.42,14.93,3,16,3c0,0,0,0,0,0c1.07,0,2.07,0.42,2.83,1.18 c1.56,1.58,1.56,4.15,0,5.73c0,0,0,0.01-0.01,0.01c0,0,0,0,0,0L11,18.06L3.19,9.92z"></path>                
                    </svg>
                </a>                  
            }
    </>)

}

export default Wishlist;