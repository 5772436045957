
import {Navigate,useLocation} from 'react-router-dom';
import authClass from './utility/authclass';
const Protected =({children} )=>{ 
    console.log("protected call");
    const location = useLocation(); 
    console.log(authClass.getUserSession());         
    if(authClass.getUserSession()===false || Object.keys(authClass.getUserSession()).length== 0 ){
        console.log('protected');
        console.log(location.pathname);
        authClass.updateRedirectpath(location.pathname);
        return <Navigate to="/login" replace />;
    }
   
    return children;


}

export default Protected;