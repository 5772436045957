import { Button, Form, Input, notification, Switch } from 'antd';
import customerObj from '../../utility/customerClass'
import { useParams , Link,useNavigate} from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/index';
import queryString from "query-string"
const ResetPassword = (props) => {
    const navigate = useNavigate()
    
    const queryParams = new URLSearchParams(window.location.search)
    const token = queryParams.get("token")
    const onFinish = async (values) => {
       let password = values.password;
        const data = {token,password}        
        const customerData = await customerObj.resetPassword(data);
        console.log(customerData);
        if(Object.keys(customerData).length>0){
            //console.log('succsess'+customerData);
            notification.success({message:'Password Changed Successfully'})
            setTimeout(() => {
                navigate('/login')
            }, 2000)
        }
    };


    return (<>
        <Header></Header>
        <div className="single-product-area">
            <div className="zigzag-bottom"></div>
            <div className="container">
                <div className="row">
                <div className="col-md-6">
                </div>
                <div className="col-md-6">
                    <h3>Reset Password </h3>
                <div className="loginBox">
                    <Form   
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 6 }}            
                        name="resetpassword"
                        onFinish={onFinish}                      
                    >
                        <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                    {
                        required: true,
                        message: 'Please input your password!',
                    },
                    ]}
                    hasFeedback
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    name="confirm"
                    label="Confirm Password"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                    {
                        required: true,
                        message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                        }

                        return Promise.reject(new Error('The two passwords that you entered do not match!'));
                        },
                    }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                    offset: 8,
                    span: 16,
                    }}
                >
                    <Button type="primary" htmlType="submit" className='ant-btn ant-btn-primary'>
                    Save
                    </Button>
                </Form.Item>

                </Form>
                 </div>
                </div>
                </div>
            </div>
        </div>
        
        <Footer></Footer>


      

    </>)
}
export default ResetPassword