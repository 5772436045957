
import cartObj from '../../utility/cartClass'
import commonObj from '../../utility/commonclass'
import {Link} from 'react-router-dom'
const Summary = (props) => {
    const cartData = cartObj.cartData;

    return (<>
        <div className="cart_totals ">

            <table cellspacing="0">
                <thead>
                    <tr>
                        <th colspan="2"><h3>Cart Totals</h3></th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="cart-subtotal">
                        <th>Cart Subtotal</th>
                        <td><span className="amount">{cartObj.cartGrossTotalDisplay()}</span></td>
                    </tr>

                    <tr className="shipping">
                        <th>Shipping and Handling</th>
                        <td>{cartObj.shippingTotalDisplay(false)}</td>
                    </tr>

                    <tr className="order-total">
                        <th>Order Total</th>
                       <td><strong><span className="amount">{cartObj.cartTotalDisplay()}</span></strong> </td>
                    </tr>

                    <tr >
                        <th colspan="2"><Link to="/checkout" className='btn'>Proceed to Checkout</Link></th>

                    </tr>
                </tbody>
            </table>

        </div>
    </>)

}

export default Summary;