import { Button, Form, Input, Switch,Spin } from 'antd';
import { useState, useEffect,useRef } from 'react';
import CustomerObj from '../../utility/customerClass';
import { useNavigate , Link} from 'react-router-dom';
import Config from '../../config/index';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import cartObj from '../../utility/cartClass'
const amount = "2";
const currency = "USD";
const style = {"layout":"vertical"};

const PAYPALPayFlow = (props) =>{
   const inputRef = useRef(null)
    const navigate= useNavigate()
  const order=props.order;
  const address=props.address;
  //console.log('order satish');
  //console.log(order);

let [captchaurl,setCaptchaUrl]=useState("");
let [captchaNumber,setCaptchaNumber]=useState("");
let [cartTotal,setCartTotal]=useState(100);
let [spinning,setspinning]=useState(false);




useEffect(() => {
  console.log("test order");
  console.log(order);
  console.log(address);
//const total=  cartObj.cartTotal();
//setCartTotal(total);
//console.log(Config.url);
//let url = Config.url+'payment/'+order.id;
//console.log(url);
//window.location.href = url;
//AX6ssF8XJAXdSJnHFH9mSzJ0Yj7N4-KK2z1qqVoI3o8oH8gDZJ37fGbortthQgZ4E135RH2g-Cvcb8oK
}, [])




    return (<>
      <Spin tip="Please Wait..." spinning={spinning}>
   { <div className="paypalbox" ref={inputRef}>
      <PayPalScriptProvider options={{ "client-id": "AWQIGhRR6Ivvip6iDYJ5GdGUTMqkZ6r3QiG-fwEdFAkjrAsTtYGSS82AI0toC9iFNg6noAnhHtcxNi9i" }}>
      <PayPalButtons
                style={style}
                disabled={false}
                forceReRender={[amount, currency, style]}
                onInit={(data, actions)=>  {
                    console.log("check lskflksf");
                    console.log(data);
                }}
                fundingSource={undefined}
                createOrder={(data, actions) => {
                    return actions.order
                        .create({
                            application_context: {
                                shipping_preference: 'SET_PROVIDED_ADDRESS',
                              },
                            payer: {
                                //birth_date: '2021-01-01',
                                email_address: order.email,
                                phone: {
                                  phone_number: {
                                      national_number:  order.mobile,
                                  }
                                },
                                name: {
                                  given_name: address?.firstName,
                                  surname: address?.lastName,
                                },
                                address: {
                                  address_line_1: address?.addressLine1,
                                  address_line_2: address?.addressLine2,
                                  admin_area_2: address?.city?.name,
                                  admin_area_1:address?.state?.code,
                                  postal_code: address?.pincode,
                                  country_code: address?.country?.sortname,
                                },
                              },
                            purchase_units: [
                                {
                                    amount: {
                                        currency_code: currency,
                                        value: cartTotal,
                                    },
                                    shipping: {
                                        address: {
                                          address_line_1: address?.addressLine1,
                                          address_line_2: address?.addressLine2,
                                          admin_area_2: address?.city?.name,
                                          admin_area_1:address?.state?.code,
                                          postal_code: address?.pincode,
                                          country_code: address?.country?.sortname,
                                        }
                                      }
                                },
                            ],
                        })
                        .then((orderId) => {
                            // Your code here after create the order
                            return orderId;
                        });
                }}
                onApprove={function (data, actions) {
                    return actions.order.capture().then(function (details) {
                       // console.log(data);
                        //console.log(actions);
                        setspinning(true);
                        props.updatepayment(details);
                        console.log(details);
                        
                        //props.updatepayment(details);
                        // Your code here after capture the order
                    });
                }}
            />
        </PayPalScriptProvider>
        </div>
            }
            </Spin>
            </>

    )

}
 export default PAYPALPayFlow;
