import { Link } from "react-router-dom";
import { useState,useEffect } from "react";
import adminObj from '../../utility/adminClass'
import commonObj from '../../utility/commonclass'
import { notification } from "antd";

const Footer =function(props){
   
   const metakey="socialSetting";
   const [facebooklink, setfacebooklink] = useState([]);
   const [twitterlink, settwitterlink] = useState([]);
   const [youtubelink, setyoutubelink] = useState([]);
   const [instagramlink, setinstagramlink] = useState([]);
   const [email, setEmail] = useState([]);
   //const [social, setSocial] = useState([]);
   useEffect(() => {
      async  function  getSocialData(){ 
      let metaKey = {metaKey:metakey};
      let socialData = await adminObj.getSocial(metaKey);
      let tmpdata={};
         if(commonObj.isEmpty(socialData?.metaValue)==false){
            console.log(socialData?.metaValue);
            setfacebooklink(tmpdata.facebook);
            settwitterlink(tmpdata.twitter);
            setyoutubelink(tmpdata.youtube);
            setinstagramlink(tmpdata.instagram);
           // tmpdata= JSON.parse(socialData?.metaValue)
         }
     
     
   
     // setSocial(tmpdata);
      } getSocialData();
   },[]);

   let handleSubmit = async (e) => {
      e.preventDefault();
      let emailData = {email:email};
      if(email == ''){
         notification.error({message:'Please enter valid email-id'});
      }else{
         
         let data = await adminObj.emailSubscription(emailData);
         console.log(data);
         if(Object.keys(data).length>0){
            notification.success({message:'Thank you for your subscription.'})
         } 
      }
   }

return (
    <>
 <footer>
         <div className="footer__area footer-bg">
            <div className="footer__top footer__top-space">
               <div className="container">
                  <div className="row">
                     <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-6">
                        <div className="footer__widget footer__widget-border footer-col-1 ">
                           <div className="footer__info">
                             
                              <div className="footer__subscribe">
                                 <p>Complete equipment for your home workshop or company!</p>
                                 <form onSubmit={handleSubmit}>
                                    <div className="footer__subscribe-input">
                                       <input type="email" placeholder="Enter Email ID ..." onChange={(e) => setEmail(e.target.value)} />
                                       <button type="submit"><i className="fa fa-telegram"></i></button>
                                    </div>
                                 </form>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-sm-6">
                        <div className="footer__widget footer-col-2 pb-20">
                           <h3 className="footer__widget-title">Need help</h3>
                           <div className="footer__widget-content">
                              <div className="footer__contact">
                                 <h3 className="footer__contact-phone"><Link to="tel:(877)328-6676">(877)328-6676</Link></h3>
                                 
                                 <div className="footer__contact-email">
                                    <p><Link to="mailto:sales@itsuperstore.us">sales@itsuperstore.us</Link></p>
                                 </div>
                                 <div className="footer__social">
                                    <ul>
                                        
                                       <li>
                                          <a href={facebooklink}>
                                          <i className="fa fa-facebook-f"></i>
                                          </a>
                                       </li>
                                       <li>
                                          <a href={twitterlink}>
                                          <i className="fa fa-twitter"></i>
                                          </a>
                                       </li>
                                       <li>
                                          <a href={instagramlink}>
                                          <i className="fa fa-instagram"></i>
                                          </a>
                                       </li>
                                       <li>
                                          <a href={youtubelink}>
                                          <i className="fa fa-youtube"></i>
                                          </a>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-4 col-sm-6 col-6">
                        <div className="footer__widget footer-col-3 pb-20">
                           <h3 className="footer__widget-title">Company</h3>
                           <div className="footer__widget-content">
                              <div className="footer__links">
                                 <ul>

                                 <li>
                                       <Link to="/about-us">About Us</Link>
                                    </li>
                                    <li>
                                       <Link to="/privacy-policy">Privacy Policy</Link>
                                    </li>
                                    <li>
                                       <Link to="/contact-us">Contact Us</Link>
                                    </li>
                                    <li>
                                       <Link to="/term-condition">Terms & Conditions</Link>
                                    </li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-4 col-sm-6 col-6">
                        <div className="footer__widget footer-col-4 pb-20">
                           <h3 className="footer__widget-title">SUPPORTS</h3>
                           <div className="footer__widget-content">
                              <div className="footer__links">
                                 <ul>
                                    <li>
                                       <Link to="/shipping-policy">Shipping Policy</Link>
                                    </li>
                                    <li>
                                       <Link to="/return-policy">Return Policy</Link>
                                    </li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-4 col-sm-6 col-6">
                        <div className="footer__widget footer-col-5 pb-20">
                           <h3 className="footer__widget-title">My Account</h3>
                           <div className="footer__widget-content">
                              <div className="footer__links">
                                 <ul>
                                    <li>
                                       <Link to="/customer">My Account</Link>
                                    </li>
                                    <li>
                                       <Link to="/customer/myorder">My Order</Link>
                                    </li>
                                    <li>
                                       <Link to="/customer/wishlist">Wish List</Link>
                                    </li>
                                    <li>
                                       <Link to="/customer/address">Address</Link>
                                    </li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         
            <div className="footer__bottom">
               <div className="container">
                  <div className="row">
                  
                     <div className="col-xxl-12">
                        <div className="footer__bottom-wrapper text-center">
                           <div className="footer__copyright">
                              <p className={"text-center"}>Copyright ©2024 It Superstore All Rights Reserved Developed by:<Link to="#"> Virtual Stacks Systems</Link></p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </footer>

</>


)

}
export default Footer;