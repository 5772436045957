import config from '../config/index'
import common from '../utility/commonclass';
import { Skeleton } from 'antd';
import {getProductVariantService,callApi,getProducts} from '../services/product.service'
import { useParams, Link } from 'react-router-dom';
import MyImage from '../components/Common/MyImage';
class Order {

    static myInstance = null;
    curreny = '$';
    cartData = {}
    constructor() {

    }
    static getInstance() {
        if (Order.myInstance == null) {
            Order.myInstance = new Order();
        }

        return this.myInstance;
    }
    
     getOrderTotalDisplay(order){
        return `${order.currency}${order.totalPrice}`;
     }

     getsubTotalwithCurrency(order){

        return `${order.currency}${order.subTotalPrice}`;
     }

     getTaxWithCurrency(order){
        if(order.taxAmount > 0){
            return `${order.currency}${order.taxAmount}`;
        }else{
            return "---";
        }
        
     }

     getDiscountWithCurrency(order){
        if(order.discountAmount > 0){
            return `${order.currency}${order.discountAmount}`;
        }else{
            return "---";
        }
        
     }

     getShippingWithCurrency(order){
        if(order.shippingAmount > 0){
            return `${order.currency}${order.shippingAmount}`;
        }else{
            return "---";
        }
        
     }


     

     

     getOrderItemName(orderItem){
        return `${orderItem.productName}`;
     }
     getOrderItemPrice(orderItem,currency){
        return `${currency}${orderItem.unitPrice}`;
       
     }

     getOrderItemUnit(orderItem){
        return `${orderItem.quantity}`;
       
     }

     getOrderItemTotalPrice(orderItem,currency){
        return `${currency}${orderItem.unitPrice*orderItem.quantity}`;
       
     }

     PaymentStatus(order){
         return order.paymentStatus==0?"Not Paid":"Paid";
     }

}
let Instance = Order.getInstance();
export default Instance;