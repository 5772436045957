let development={
//url:'http://localhost:3002/',
//url:'http://75.112.188.133:8080/',
url:'https://apisuperstore.virtualstacks-demosite.com/',
sessionkey:'_itstore',
adminsessionkey:'_adminitstore',
pathKey:'_itstore.path',
cartKey:'_itstore._cart',
captchaSiteKey:'6LeS2WEpAAAAALrVc0NwrfUUlVykrtDtcrm-r-yI',
}

let production={
    url:'',
    sessionkey:'_itstore',
    adminsessionkey:'_adminitstore',
    cartKey:'_itstore._cart',
    pathKey:'_itstore.path',
    captchaSiteKey:'6LeS2WEpAAAAALrVc0NwrfUUlVykrtDtcrm-r-yI',
}
let config=(process.env.REACT_APP_ENVIRONMENT==='production')?production:development;

export default config;
