
 
// import 'font-awesome/css/font-awesome.min.css';
//new import css...//
import React from 'react';
import './App.css';
import './css/preloader.css';
//import './css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/meanmenu.css';
import './css/animate.css';
import './css/owl-carousel.css';
import './css/swiper-bundle.css';
import './css/backtotop.css';
import './css/magnific-popup.css';
import './css/nice-select.css';
import './css/default.css';
//import './css/font-awesome-pro.css'; 
import './css/style.css';
import Home from './Home';
import {loadCartInt} from './redux/action/cartAction';
import {int} from './redux/action/userAction';
import Product from './components/Product/Product';
import ProductCategory from './components/Product/ProductCategory';
import ProductSearchList from './components/Product/ProductSearchList';
import CustomerLayout from './layouts/customer.layout'
import Profile from './components/Customer/Profile';
import AddressPage from './components/Customer/Address';
import AddressEditPage from './components/Customer/AddressEdit';
import AddressAddPage from './components/Customer/AddressAdd';
import WishlistPage from './components/Customer/Wishlist';
import MyOrderPage from './components/Customer/MyOrder';
import MyOrderDetail from './components/Customer/MyOrderDetail';
import ChangePasswordPage from './components/Customer/ChangePassword';

import Auth from './components/Auth/Auth';
import Thanks from './components/Checkout/Thanks';
import Cancel from './components/Checkout/Cancel';
import Registration from './components/Auth/Register';
import ProductList from './components/Product/ProductList';
import CartPage from './components/Cart/index';
import CheckoutPage from './components/Checkout/index';
import AboutUs from './components/Pages/About';
import ContactUs from './components/Pages/Contact';
import PrivacyPolicy from './components/Pages/PrivacyPolicy';
import ReturnPolicy from './components/Pages/ReturnPolicy';
import ShippingPolicy from './components/Pages/ShippingPolicy';
import TermsCondition from './components/Pages/TermCondition';
import ForgotPassword from './components/Customer/ForgotPassword';
import ResetPassword from './components/Customer/ResetPassword';
import PageNotFound from './components/404Page/PageNotFound';

// Admin Layout

import AdminLayout from './layouts/admin.layout';
import AdminLoginLayout from './layouts/AdminLogin';
import ProtectedAdmin from './protectedadmin.route';
import Category from './components/Admin/Category';
import Social from './components/Admin/Social';
import SeoSettingList from './components/Admin/SeoSetting/List';
import SeoSettingEdit from './components/Admin/SeoSetting/Edit';

import ShippingRulesList from './components/Admin/ShippingRules/List';
import ShippingRulesEdit from './components/Admin/ShippingRules/Edit';
import ShippingRulesAdd from './components/Admin/ShippingRules/Add';
import CareerManagementList from './components/Admin/careerManagement/List';
import Scrape from './components/Admin/Setting/Scrape';


import {Routes,Route} from 'react-router-dom';
import {useSelector,useDispatch} from 'react-redux';
import cartObj from './utility/cartClass'
import authObj from './utility/authclass'
import Protected from './protected.route';
import { useEffect } from 'react';
function App() {
  const dispatch=useDispatch();
  const cartData=useSelector((state)=> state.cartReducer);  
  const userData=useSelector((state)=> state.userReducer); 
  
  cartObj.setCartData(cartData);
  authObj.setData(userData);

  useEffect(()=>{
    dispatch(loadCartInt());
    dispatch(int());
    
  },[])
 


  return (

    <Routes>
      <Route path="/" element={<Home/>}></Route>
      <Route path="/product/" element={<ProductList/>}></Route>  
     
      <Route path="/productcategory/" element={<ProductCategory/>}></Route> 
      <Route path="/search/" element={<ProductSearchList/>}></Route>   
      <Route path="/product/:sku" element={<Product/>}></Route>    
      <Route path="/cart" element={<CartPage/>}></Route>
      <Route path="/checkout" element={<Protected><CheckoutPage/></Protected>}></Route>
      <Route path="/login" element={<Auth/>}></Route>
      <Route path="/registration" element={<Registration/>}></Route>
      <Route path="/about-us" element={<AboutUs/>}></Route>
      <Route path="/contact-us" element={<ContactUs/>}></Route>
      <Route path="/term-condition" element={<TermsCondition/>}></Route>
      <Route path="/shipping-policy" element={<ShippingPolicy/>}></Route>
      <Route path="/return-policy" element={<ReturnPolicy/>}></Route>
      <Route path="/privacy-policy" element={<PrivacyPolicy/>}></Route>
      <Route path="/forgot-password" element={<ForgotPassword/>}></Route>
      <Route path="/reset-password" element={<ResetPassword/>}></Route>
      <Route path="/thanks" element={<Thanks/>}></Route>
      <Route path="/cancel" element={<Cancel/>}></Route>
      <Route path ="/customer" element={<Protected><CustomerLayout></CustomerLayout></Protected>}>     
        <Route index element={<Profile />}></Route>
        <Route path="/customer/myorder/:id" element={<MyOrderDetail />}></Route>
        <Route path="/customer/myorder" element={<MyOrderPage />}></Route>
        <Route  path="/customer/address" element={<AddressPage />}></Route>
        <Route  path="/customer/add-address/" element={<AddressAddPage />}></Route>
        <Route  path="/customer/address/:id" element={<AddressEditPage />}></Route>       
        <Route  path="/customer/wishlist" element={<WishlistPage />}></Route>
        <Route  path="/customer/changepassword" element={<ChangePasswordPage />}></Route>
      </Route>

      <Route path ="/admin/login" element={<AdminLoginLayout></AdminLoginLayout>}>  </Route>

     <Route path ="/admin" element={<ProtectedAdmin><AdminLayout></AdminLayout></ProtectedAdmin>}>     
        <Route index element={<Profile />}></Route>
        <Route path="/admin/category" element={<Category />}></Route>
        <Route path="/admin/social" element={<Social />}></Route>
        <Route path="/admin/seoSetting" element={<SeoSettingList />}></Route>
        <Route path="/admin/seoSettingEdit/:id" element={<SeoSettingEdit />}></Route>
         <Route path="/admin/shippingRules" element={<ShippingRulesList />}></Route>
        <Route path="/admin/shippingRulesEdit/:id" element={<ShippingRulesEdit />}></Route>
        <Route path="/admin/shippingRulesAdd/" element={<ShippingRulesAdd />}></Route>
        <Route path="/admin/careerManagement/" element={<CareerManagementList />}></Route>
         <Route path="/admin/scrapeSetting/" element={<Scrape />}></Route>

        
  </Route>
  <Route path="*" element={<PageNotFound/>}></Route>
   </Routes>
    
  );
}

export default App;
