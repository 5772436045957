import config from '../config/index'
import common from '../utility/commonclass';
import { Link } from 'react-router-dom';
import loginservice from '../services/login.service.js'
import {update} from '../services/customer.server'
import { notification } from 'antd';

class AdminAuth {
    static myInstance = null;
    static userData = null;
    static token = null;


    static getInstance() {
        if (AdminAuth.myInstance == null) {
            AdminAuth.myInstance = new AdminAuth();
        }

        return this.myInstance;
    }

    setData(data = {}) {
        this.userData = data.user;
        this.token = data.token;
    }

    getUserData(){
        const userdata=this.getUserSession();
        if(userdata){
            return userdata.user
        }else{
            return {}
        }

    }
    loadData(state) {     
        try {
            const userData = this.getUserSession();           
            if (userData)
                return { ...userData }
            else {
                return { ...state }
            }

        } catch (e) {
            console.warn(e);
            return { ...state }
        }

    }


    setUserSession(session) {
        console.log(btoa(JSON.stringify(session)));
        sessionStorage.setItem(config.adminsessionkey, btoa(JSON.stringify(session)));
    }

    updateRedirectpath(path){
        sessionStorage.setItem(config.pathKey, btoa(path));        
    }

    getRedirectpath(){
        const data = sessionStorage.getItem(config.pathKey);
        console.log(data);
        return (atob(data) !== "") ? atob(data) : "";       
    }


    getUserSession() {
        const data = sessionStorage.getItem(config.adminsessionkey);
        console.log(data);
        return (common.isEmptyObject(data) === true) ? false : JSON.parse(atob(data));

    }
    updateUserSession(data){
        const userdata=  this.getUserSession();
        const newuserdata={...userdata,user:{...userdata.user,...data}}
        this.setUserSession(newuserdata);
    }

    getToken() {

        return this.token;
    }

    async getCurrentUser() {
        const userdata= this.getUserSession()        
        return await userdata.user;
    }
    getCurrentName() {
        if (this.userData) {
            return this.userData.firstName + ' ' + this.userData.lastName;
        } else {
            return "";
        }

    }

    isLogin() {
        return (this.token) ? true : false;

    }
    logout() {
        sessionStorage.setItem(config.adminsessionkey, btoa(JSON.stringify({})));
        localStorage.setItem(config.cartKey, JSON.stringify({}));

    }

    async login(values) {
        try {
            const res = await loginservice.getAdminLogin(values);
            console.log('dkkdd');
            if (res.status == 0) {
                notification.error({ key: 'login', message: res.errormessage });
                return false;
            } else {
                const userdata = res.data;
                this.setUserSession(userdata);
                notification.success({ key: 'login', message: res.message });
                //this.loadData()
                return userdata;

            }
        } catch (e) {
            return false;
        }



    }


    removeUserSession() {
        sessionStorage.removeItem(config.adminsessionkey);
    }
   

    openLoginBox() {


    }

    /**************** Customer*************/
    updateCustomer(id,data){

        const res= update(id,data);
        this.updateUserSession(data);
        return res;
    }


    /******************* Link***************/
    getLoginLink() {
        /*const loginbtn=()=>{
                this.openLoginBox();

        }*/
        return <Link to="/login" ><i className="fa fa-user"></i>Login</Link>

    }
    getRegisterLink() {
        return <Link to="/login" ><i className="fa fa-user"></i>Register</Link>
    }

    getProfileLink(child) {
        return <Link to="/customer/" >{child}</Link>
    }
    



}
let Instance = AdminAuth.getInstance();
export default Instance;
