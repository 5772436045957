import {useSelector,useDispatch} from 'react-redux';
import cartObj from '../../utility/cartClass'
import productObj from '../../utility/productClass'
import { Link } from "react-router-dom";

import { addToCartAction } from '../../redux/action/cartAction'
import { InputNumber, notification, Popconfirm, Empty,Drawer } from 'antd';
import React, { useState, useEffect,memo } from 'react';
import { DeleteOutlined,ShoppingCartOutlined } from '@ant-design/icons';
import './HeaderCart.css';
const HeaderCart = function (props) {  

    const deletekey = 'delete';
    const key = 'updatable';
    const dispatch = useDispatch();
    const qty=cartObj.getCartCount();
    const cartData=cartObj.cartData;
    const [open, setOpen] = useState(false);
    
    useEffect(()=>{
        console.log(cartData);
    },[])

    const deleteCartItem = async (item)=>{
        const product = cartData[item].product;
        await cartObj.deleteCartLocalStorage(product);
        dispatch(addToCartAction(product));
        notification.success({ deletekey, message: 'Item has been  removed Successfully' })
    }

    const onchangeHandel = async (qty, item) => {
        const product = cartData[item].product;
        await cartObj.updateCartLocalStorageQty(product, qty);
        dispatch(addToCartAction(product));
        notification.success({ key, message: 'Update Cart Successfully' })

    }

    const showLargeDrawer  = () => {
        console.log("click");
        setOpen(true);
     
      };
      const onClose = () => {
        setOpen(false);
      }; 
    return (<>
        <div className="shopping-item">
   <Link to="" onClick={(e)=>showLargeDrawer()}>Cart - <span className="cart-amunt">{cartObj.cartGrossTotalDisplay()}</span><ShoppingCartOutlined  style={{ fontSize:20, color: "blue" }} /> <span className="product-count">{qty}</span> </Link>
   </div>
   { qty > 0 &&   <Drawer title="View Cart Item" placement="right" width={500} onClose={onClose} visible={open}>  
                    <div className="Cart-box">
                     <table className="table table-hover table-fixed table-responsive ">
                      <thead>
                        <tr>
                                <th>Image</th>
                                <th>Product Name</th>
                                <th>Price</th>
                               <th>Total Price</th>
                               
                        
                            </tr>
                        </thead>
                        <tbody>
                        {
                            Object.keys(cartData).map((item,itemkey)=>{
                               
                                return (<tr key={itemkey}>                                    
                                    <td style={{width: "80px"}} className={"cart-image"}>                                    
                                        <div className='cart_it_img'>
                                        {productObj.productImage({productName:cartData[item].product.productName,productImage:cartData[item].product.productImage})}
                                        </div>
                                    </td>
                                    <td style={{width: "200px"}}>
                                      <p className='p_name'>{cartData[item].product.productName}</p>  
                                  
                                   
                                    </td>
                                    <td style={{width: "100px"}}>
                                            <p className='p_remove'>
                                        {cartObj.PriceDisplay(cartData[item].product)}
                                      </p>
                                   <p><InputNumber min={1} className="input-text qty text form-control" value={cartData[item].cartQty}  onChange={(e) => onchangeHandel(e, item)} /></p> 
                                  
                                    </td>
                                    <td style={{width: "120px"}} >
                                    <p className='p_remove'>{cartObj.cartItemTotalDisplay(cartData[item])}</p>
                                       <p className='p_remove'>
                                        <Popconfirm title="Do you  want to Delete？"onConfirm={(e)=>deleteCartItem(item)}>
                                         <Link to="" ><DeleteOutlined /> <span>Remove</span></Link>
                                        </Popconfirm>
                                      </p>
                                    </td>
                                    
                                 
                                                                                                       
                                 

                                    
                                </tr>)
                            })
                        
                        }
                             
                        </tbody>
                    </table>
                    <div  className="cart-link-box">
                        {cartObj.getCartLink()}
                    </div> 
                    </div>
                    
                                      
                    </Drawer>}
      
    </>)
}
export default  HeaderCart;