     
import {Outlet} from 'react-router-dom'
import Menu from '../components/Admin/Common/Menu'
import Header from '../components/Admin/Common/Header'
import Footer from '../components/Admin/Common/Footer'
import '../components/Admin/Admin.css';
export default function AdminLayout(){


return(
<>

<Menu></Menu>
<div className="wrapper d-flex flex-column min-vh-100 bg-light">
     <Header></Header>
     <div className="body flex-grow-1 px-3">
        <div className="container-lg">
             <Outlet></Outlet>
        </div>
    </div>
    <Footer></Footer>
</div>


</>

) 

}
