import { Button, Form, Input, Switch, Result } from 'antd';
import authObj from '../../utility/authclass'
import { useDispatch } from 'react-redux';
import { int } from '../../redux/action/userAction';
import { useNavigate , Link} from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/index';
const ShippingPolicy = (props) => {
    
    const componentSize = 'large';
    const page_title = {
        fontSize: "24px",
        lineHeight: "30px",
        fontWeight: "700",
        boxShadow: "0 -1px #eee inset",
        textTransform: "capitalize"
    }
    const page_title_wrapper = {
        display: "inline-block",
        padding: "5px 0 7px",
        borderBottom: "3px solid #01adeb"
    }
    const container_top = {
       
        fontFamily: "Arial",
       
    };
    const box_half ={
      width: "50%",
      padding: "0 15px"
    }
    const box_layout = {
        position: "relative",
        display: "flex",
        flexWrap: "wrap",
        margin: "0 -15px"
    }
    return (<>
        <Header></Header>
        <div className="container"  style={container_top}>   
            <div class="page-title-wrapper"><h1 class="page-title" style={page_title}><span class="base" data-ui-id="page-title-wrapper" style={page_title_wrapper}>Shipping Policy</span></h1></div>
            <p>Orders received and approved before 5PM Eastern time will generally ship the same business day. You will receive an order confirmation email along with the tracking number as soon as it is available. The tracking number will allow you to track your shipment in real time.</p>
            <p>During the quick and secure checkout process you may select from several shipping methods from UPS & FedEx. Methods of shipping include:</p>
            <div style={box_layout}>
            <div style={box_half}>
            <h4>FedEx Ground Service</h4><ol> <li>FedEx First Overnight</li> <li>FedEx Priority</li> <li>FedEx Standard Overnight</li> <li>FedEx 2nd Day</li> <li>FedEx Express Saver</li> <li>FedEx International Priority</li> <li>FedEx International Economy</li> </ol>
            </div>
            <div style={box_half}>
            <h4>UPS Ground</h4><ol> <li>UPS 3-Day Select</li> <li>UPS 2nd Day Air</li> <li>UPS Next Day Air Saver</li> <li>UPS Next Day Air Early AM</li> <li>UPS Next Day Air</li> </ol>
            </div>
            </div>
        </div>
        <Footer></Footer>


      

    </>)
}
export default ShippingPolicy