import { AutoComplete, Input,Spin, Button,Empty ,Select,Form} from 'antd';
import React, { useState, useEffect } from 'react';
import { Link,useNavigate,useSearchParams } from "react-router-dom";
import {getProducts} from '../../services/product.service';
import { SearchOutlined } from '@ant-design/icons';

const provinceData = ['Global', 'Sku'];
const HeaderSearch = function (props) {
    let navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams(); 
    const [options, setOptions] = useState([]);
    const [search, setSearch] = useState("");
    const [searchType, setsearchType] = useState("Global");
    const [loader, setloader] = useState(false);
    
useEffect(()=>{
  console.log("tttt");
  console.log();
  setSearch(searchParams.get('s'))
},[])

    const searchResult = (data) =>{

      return data.map((item,itemkey)=>{
          return {value:item.productSku,label:(
          <><a >{`${item.productSku} - ${item.productName}`}</a></>
          )}
      })
  }



    const handleSearch = (value) => { 
        let filter={searchkey:value,isMain:true};
        setloader(true);
        getProducts({filter:filter,searchType:searchType}).then((data)=>{
            setloader(false);
            //setOptions(['']);
            setOptions(value ? searchResult(data.productdata.rows) : []);
        }).catch((err)=>{

        })       
      };

      const handleChange = async (value)=>{
        console.log(value);
        await setSearch(value);
      }

      const handleKeypress = (e) => {
          if (e.keyCode === 13) {
                searchproduct();
           }
    };
    
      const onSelect = (value) => {
        console.log('onSelect', value);
        navigate('/search/?s='+encodeURI(value)+'&type='+encodeURI(searchType))
      };

    
      const searchproduct=()=>{
         // console.log(search);
          if(search){
            navigate('/search/?s='+encodeURI(search)+'&type='+encodeURI(searchType))
          }else{
            navigate('/search');
          }       
      }

      const handleProvinceChange = (value) => {
        setsearchType(value);
      };
    return (<>
        <div className="auto-search">
<Form>




            {loader === true && <div className="search-loader">
                <Spin />
            </div>}

           
                <div className="search-typebox">
                <Select
        defaultValue={provinceData[0]}
        style={{
          width: 100,
        }}
        onChange={handleProvinceChange}
        options={provinceData.map((province) => ({
          label: province,
          value: province,
        }))}
      />
                </div>
           
            <AutoComplete

                style={{
                    width: "100%",
                    
                }}
                options={options}
                onSelect={onSelect}
                onSearch={handleSearch}
                onChange={handleChange}
                value={search}
                onKeyDown={handleKeypress}
                notFoundContent={<Empty />}
            >
                <Input size="large" placeholder="Search here" />

            </AutoComplete>
           
            <Button type="primary" style={{
                    right: 0,
                    top:0
                    
                }} className="button-search" shape="circle" icon={<SearchOutlined />} size="large" onClick={searchproduct} />
            </Form>
        </div>
    </>)
}
export default HeaderSearch;