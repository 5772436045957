import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const MyImage = (image) => (
  
  

    <LazyLoadImage 
      src={image.src} // use normal <img> attributes as props
      placeholderSrc={require("../../images/product.png")}
      afterLoad={image.afterLoad}
      />
     
);

export default MyImage;