import { addToCartAction } from '../../redux/action/cartAction'
import Variant from './Variant';
import AuthObj from '../../utility/authclass';
import cartObj from '../../utility/cartClass';
import { useDispatch } from 'react-redux';
import { InputNumber, notification } from 'antd';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
const CartButton = (props) => {
    const dispatch = useDispatch();
    const [qty, setQty] = useState(1)
    const [selectvariant, setselectVariant] = useState({})
    const addToCart = async () => {
        if (props.product.isVariant === 1 && props.product.variantData && Object.keys(props.product.variantData.attributes).length !== Object.keys(selectvariant).length) {
            notification.warning({ message: 'Please Select Product Variant' });
            return false;

        }
        //selectVarintKey:props.product.selectVarintKey, 
        const cartProduct = {
            id: props.product.id,
            isVariant: props.product.isVariant,
            productPrice: props.product.productPrice,
            productSku: props.product.productSku,
            productType: props.product.productType,
            productSku: props.product.productSku,
            weight: props.product.weight,
            productImage: props.product.productImage,
            productName: props.product.productName,
            productCategory: props.product.productCategory,
        }

        if (props.product.isVariant === 1) {           
            const selectVarintKey = props.product.selectVarintKey;
            const variantNumber=props.product.variantData.varintKey[selectVarintKey].variantNumber;
            const varintdetail=[];            
            for (const property in props.product.variantData.varintnumber[variantNumber]){            
                const tmp={attributeId:property,
                    attributeOptionsId:props.product.variantData.varintnumber[variantNumber][property].attributeOption['id'],
                    attributeName:props.product.variantData.varintnumber[variantNumber][property].attribute['name'],
                    attributeOptionName:props.product.variantData.varintnumber[variantNumber][property].attributeOption['optionName']            
                 }
                 varintdetail.push(tmp);

            }
            cartProduct.productPrice = props.product.variantData.varintKey[selectVarintKey].price;           
            cartProduct.selectVarintKey=props.product.selectVarintKey;
            cartProduct.variantNumber = variantNumber;
            cartProduct.varintDetail=varintdetail;



        }
        
        await cartObj.saveCartLocalStorage(cartProduct, qty);
        dispatch(addToCartAction(cartProduct));
        notification.success({ message: 'Add To Cart Successfully' })
    }

    const onChange = (value) => {
        if (value)
            setQty(value);
    };

    const clickVariant = async (value, attributeId) => {
        await setselectVariant((pre) => {
            const data = { ...pre };
            data[attributeId] = value.id;
            console.log(data);
            if (props.product.isVariant === 1 && props.product.variantData && Object.keys(props.product.variantData.attributes).length === Object.keys(data).length) {
                props.loadVarintData(data);
            }

            return { ...data }
        })
    }
    return (<>
        {
            props.product.variantData && <div className="variant-box">
                {
                    Object.keys(props.product.variantData.attributes).map((item, itemKey) => {
                        return (
                            <Variant selectvariant={selectvariant} clickVariant={clickVariant} variantData={props.product.variantData.attributes[item]} key={itemKey}></Variant>

                        )


                    })
                }

            </div>

        }

        {props.isqty == true && <div className="quantity">
            <InputNumber min={1} max={10} className="input-text qty text" defaultValue={qty} onChange={onChange} />

        </div>}
        
        <a className={`${props.classes} add_to_cart_button`} rel="nofollow"  onClick={(e) => addToCart()}>
            {props.children && props.children}
            {!props.children && 'Add To Cart'}

        </a>
    </>)

}

export default CartButton;