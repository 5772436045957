import moment from 'moment';
import commonservice from '../services/common.service'
class Common {
    static myInstance = null;

    static getInstance() {
        if (Common.myInstance == null) {
            Common.myInstance = new Common();
        }

        return this.myInstance;
    }
    isEmptyObject(obj) {       
        if (!obj) {
            return true;
        } else {

            return JSON.stringify(obj) === '{}'
        }

    }
    isEmpty(x) {

        return (                                                           //don't put newline after return
        (typeof x == 'undefined')
              ||
        (x == null)
              ||
        (x == false)        //same as: !x
              ||
        (x.length == 0)
              ||
        (x == 0)            // note this line, you might not need this. 
              ||
        (x == "")
              ||
        (!/[^\s]/.test(x))
              ||
        (/^\s*$/.test(x))
    );
    }

    dateFormat(date){
        
        return moment(date).format('DD/MM/YYYY');
    }
    dateTimeFormat(date){
        
        return moment(date).format('DD/MM/YYYY HH:mm:ss');
    }

   async getCountryList(){
       try{
        return await commonservice.getCountry();
       }catch(e){
           console.warn(e.message);
       }
      
    }

    async getStateList(countryId){

        try{
            return await commonservice.getState(countryId);
           }catch(e){
               console.warn(e.message);
           }
    }

    async getCityList(stateId){

        try{
            return await commonservice.getCity(stateId);
           }catch(e){
               console.warn(e.message);
           }
    }

    async addWishlist(data){

        try{
            return await commonservice.addWishlist(data);
           }catch(e){
               console.warn(e.message);
           }
    }

    numberFormat (value) {
        return parseFloat(value).toFixed(2);
    }

    async getPaymentMethods(){

        try{
            return await commonservice.getPaymentMethods();
           }catch(e){
               console.warn(e.message);
           }
    }

    


}
let Instance= Common.getInstance();
export default Instance;