import Login from './Login';
import Header from '../Header/Header';
import Footer from '../Footer/index';
import { useState } from 'react';

const Auth = () => {


    return (<>
        <Header></Header>
        <div className="single-product-area">
            <div className="zigzag-bottom"></div>
             <div className="container">
                <div className="row align_center">
                <div className="ragistraiton_box">
                <div className="image_box">
                <img src="assets/img/login.png" alt="ragistraiton" />
                    </div>  
                    <div className="form_box">
                    <h3>Login Now</h3>
                <div className="loginBox">
                <Login ></Login>
               </div>
               </div>
                </div>
                </div>
            </div>
            
        </div>
        
        <Footer></Footer>
    </>)
}

export default Auth;