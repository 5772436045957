import Header from '../Header/Header';
import Footer from '../Footer/index';
import cartObj from '../../utility/cartClass'
import commonObj from '../../utility/commonclass'
import customerObj from '../../utility/customerClass'
import Payment from '../Payment/index';
import COD from '../Payment/COD';
import PAYPAL from '../Payment/PAYPAL'
import PAYPALPayFlow from '../Payment/PAYPALPayFlow'
import { Card, notification, Collapse, Empty, Radio,Space } from 'antd';
import Summary from './summary'
import { Link,useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
const { Panel } = Collapse;
const CheckoutPage = (props) => {
    const navigate = useNavigate();
    const [checkoutData, setCheckout] = useState({ address: {}, payments: [] })
    const [selectPayment, setpayment] = useState(3);
    const [paymentMode, setpaymentMode] = useState(0);
    const [orderFullDetal, setOrderDetail] = useState({});
    const [showpayflow, setpayflow] = useState(0);
    const cartData = cartObj.cartData; 
    useEffect(() => {
        const getData = async () => {
            let  addressResults = await customerObj.getDefaultAddress();
            const paymentData = await commonObj.getPaymentMethods();
            console.log('addressResults ');
            console.log(addressResults);
            if(addressResults===null){
                addressResults={};
            }
            console.log('addressResults ');
            console.log(addressResults);
            setCheckout((pre) => {
                return { ...pre, address: addressResults, payments: paymentData.list }
            })

        }
        getData();



    }, [])

    const selectPaymentoption= async (id)=>{
        setpayflow(0);
        setpayment(id);
        
    }
    

    const makepayment =async ()=>{

        if(!checkoutData.address.id){
            notification.warning({key:'makepayment',message:"Please Select Address"});
            return false;
        }
        if(!selectPayment){
            notification.warning({key:'makepayment',message:"Please Select Payment Method"});
            return false;
        }

       

        console.log(checkoutData.address);
        let shippingMethod="";
        let shippingAmount=0;


        if(Object.keys(cartObj.ShippingData).length > 0){

            shippingAmount=cartObj.ShippingData['amount'];
            shippingMethod="Fedex";
        }
        let taxAmount=0;
        if(checkoutData.address?.pincode=="32746"){
            taxAmount=cartObj.cartTax();
        }

        const order={
            customerId:0,
            addressId:checkoutData.address.id,
            email:checkoutData.address.primaryEmail,
            mobile:checkoutData.address.mobileNumber,         
            currency:'$',            
            taxAmount:taxAmount,
            shippingAmount:shippingAmount,
            shippingMethod:shippingMethod,         
            discountAmount:'',
            couponDetail:'',
            paymentId:selectPayment,

        };
        const orderData={orderItem:cartData,order:order}
        console.log(orderData);
        const orderDetail=await customerObj.createOrder(orderData);
        console.log(orderDetail);
        if(orderDetail.order.id > 0){
            console.log('update Order');
            console.log(orderDetail.order);
            await setOrderDetail(orderDetail.order);
            await setpaymentMode(1);           
        }
        if(selectPayment==3){
            setpayflow(1);
            return false;
        }
        
    }

    const validateCaptcha=()=>{

        console.log('Validate Captcha');
    }

    return (<>
    {/*hideheader={1}*/}
        <Header ></Header>
        <div className="single-product-area">
            <div className="zigzag-bottom"></div>
             <div className="container">
                {Object.keys(cartData).length == 0 && <Empty description="Cart is Empty"></Empty>}
                {Object.keys(cartData).length > 0 && <div className="row">
                    {/**** paymentMode==0 && */}
                {  <div className="col-md-8">
                        <div className="product-content-right">
                            <div className="woocommerce">
                                <Collapse accordion={false} defaultActiveKey={[1, 2]}>
                                    <Panel header=" Select a delivery address" key="1" >
                                    <div className="row">
        <span className="your_address_box">
          Your addresses
        </span>
      </div>
                                        <Card>
                                            {
                                                Object.keys(checkoutData.address).length > 0 && <ul className="address-box">
                                                <li><strong>{`${checkoutData?.address?.firstName} ${checkoutData?.address?.lastName}`}</strong></li>
                                                <li>{`(${checkoutData?.address?.mobileNumber}) ${checkoutData.address?.addressLine1} ,  ${checkoutData.address?.addressLine1}`}</li>
                                                <li>{checkoutData?.address?.city && checkoutData?.address?.city.name + ' , '}
                                                    {checkoutData?.address.state && checkoutData?.address?.state.name + ' , '}
                                                    {checkoutData?.address.country && checkoutData?.address?.country?.name + ' , '}
                                                    {checkoutData?.address?.pincode}
                                                </li>

                                            </ul>
                                            }
                                            {Object.keys(checkoutData.address).length ==0 && <Link to="/customer/add-address/">Create New Address</Link>}
                                            
                                        </Card>
                                    </Panel>
                                    <Panel header="Select a payment method " key="2" style={{display:'none'}}>
                                        {
                                            checkoutData.payments.map((item, itemkey) => {

                                                return (<>
                                                  <div className="payment-item" onClick={(e)=>selectPaymentoption(item.id)}>
                                                      {selectPayment===item.id && <span className="icon-box"><i class="fa fa-dot-circle-o" aria-hidden="true"></i></span>}
                                                      {selectPayment !== item.id && <span className="icon-box"><i class="fa fa-circle-o" aria-hidden="true"></i></span>}
                                                  

                                                      <label>{item.paymentLabel}</label>
                                                  </div>  
                                                   
                                                </>
                                                )
                                            })
                                        }
                                    </Panel>

                                </Collapse>


                            </div>
                        </div>
                    </div>}
                    
                    { /*paymentMode==1 &&  <div className="col-md-8"><Payment orderFullDetal={orderFullDetal} address={checkoutData?.address}></Payment></div>*/}
                   
                    <div className="col-md-4">
{/*showpayflow==0 && */}
                    {  <Summary orderFullDetal={orderFullDetal} makepayment={makepayment} address={checkoutData.address} paymentMode={paymentMode} selectPayment={selectPayment}></Summary>}
                   


                    </div>
                </div>}
            </div> 

                           
            
        </div>
        <Footer></Footer>
    </>)

}

export default CheckoutPage;