import { Button, Form, Input, Switch, Result } from 'antd';
import authObj from '../../utility/authclass'
import { useDispatch } from 'react-redux';
import { int } from '../../redux/action/userAction';
import { useNavigate , Link} from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/index';
const PrivacyPolicy = (props) => {
    
    const componentSize = 'large';
    const page_title = {
        fontSize: "24px",
        lineHeight: "30px",
        fontWeight: "700",
        boxShadow: "0 -1px #eee inset",
        textTransform: "capitalize"
    }
    const page_title_wrapper = {
        display: "inline-block",
        padding: "5px 0 7px",
        borderBottom: "3px solid #01adeb"
    }
    const container_top = {
       
        fontFamily: "Arial",
       
      };
      
    return (<>
        <Header></Header>
        <div className="container"  style={container_top}>   
            <div class="page-title-wrapper"><h1 class="page-title" style={page_title}><span class="base" data-ui-id="page-title-wrapper" style={page_title_wrapper}>Privacy Policy</span></h1></div>
            <p>At itsuperstore.us, your privacy is a top priority. Please read our privacy policy details. Questions regarding this Policy should be directed to sales@itsuperstore.us</p>
            <p>Except, as otherwise discussed in this Privacy Policy; this document only addresses the use and disclosure of information we collect from you online. For some services, we both collect and transfer personal information to a service provider you have selected, or you may visit other merchants through links on our site who may collect and use your personal information. In those cases the third-party service provider or merchant’s privacy policy will govern how your information is handled. We encourage you to ask questions and to be very cautious before you disclose your personal information to others.</p>
            <p>This Privacy Policy will explain the following regarding use of our site:</p>
            <p>What information does itsuperstore.us collect from you?</p>
            <p>Where do we collect information from you and how do we use it?</p>
            <p>With whom do we share your information?</p>
            <p>How can you update or correct your Personal Information?</p>
            <p>What security precautions are in place to protect against the loss, misuse, or alteration of your information?</p>
            <p>A special note about children’s privacy you should carefully review privacy policies of any third party sites accessible from itsuperstore.us</p>
        </div>
        <Footer></Footer>


      

    </>)
}
export default PrivacyPolicy