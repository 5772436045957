import { Button, Result } from 'antd';
import React from 'react';

import Header from '../Header/Header';
import Footer from '../Footer/index';
const PageNotFound = (props) => {
  console.log('props'+{props});
  return (<>
  {(props?.isHide==null || props?.isHide==false) &&<Header hideheader={1}></Header>}
  <Result
    status="404"
    title="404"
    subTitle="Sorry, the page you visited does not exist."
    extra={<Button type="primary"><a href="/">Back Home</a></Button>}
  />
 {(props?.isHide==null || props?.isHide==false) && <Footer></Footer>}
  </>);
}
export default PageNotFound;