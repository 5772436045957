import { Button, Form, Input, notification } from 'antd';
import customerObj from '../../utility/customerClass'
import { useNavigate } from "react-router-dom";
const ChangePassword = () => {
    const navigate = useNavigate();
    const onFinish = (values) => {
        //console.log(values.password+''+values.id);
        customerObj.updatePassword(values.password);
        notification.success({key:'changepassword',message:'Password has been changed successfully'});
        navigate('/customer');
    };
    return (<>

        <Form   
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 8 }}            
            name="changepassword"
            onFinish={onFinish}                      
        >
            <Form.Item
        name="password"
        label="Password"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="confirm"
        label="Confirm Password"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }

              return Promise.reject(new Error('The two passwords that you entered do not match!'));
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>

        </Form>
    </>)

}
export default ChangePassword;