import Header from '../Header/Header';
import Footer from '../Footer/index';
import cartObj from '../../utility/cartClass'
import productObj from '../../utility/productClass'
import { addToCartAction } from '../../redux/action/cartAction'
import { useDispatch } from 'react-redux';
import {  DeleteOutlined} from '@ant-design/icons';
import { InputNumber, notification, Popconfirm, Empty } from 'antd';
import Summary from './summary'
import { Link } from 'react-router-dom';
const CartPage = (props) => {
    const key = 'updatable';
    const deletekey = 'delete';
    const dispatch = useDispatch();
    const cartData = cartObj.cartData;
    const onchangeHandel = async (qty, item) => {
        const product = cartData[item].product;
        await cartObj.updateCartLocalStorageQty(product, qty);
        dispatch(addToCartAction(product));
        notification.success({ key, message: 'Update Cart Successfully' })

    }

    const deleteCartItem = async (item)=>{
        const product = cartData[item].product;
        await cartObj.deleteCartLocalStorage(product);
        dispatch(addToCartAction(product));
        notification.success({ deletekey, message: 'Item has been  removed Successfully' })
    }
    return (<>
        <Header></Header>
        <div className="single-product-area">
            <div className="zigzag-bottom"></div>
            <div className="container">
                {Object.keys(cartData).length==0 && <Empty description="Cart is Empty"></Empty> }
                { Object.keys(cartData).length > 0 && <div className="row">
                    <div className="col-md-9">
                        <div className="product-content-right">
                            <div className="woocommerce">
                                <form method="post" action="#">
                                    <table cellspacing="0" className="shop_table cart">
                                        <thead>
                                            <tr>
                                                <th className="product-remove">&nbsp;</th>
                                                <th className="product-thumbnail">&nbsp;</th>
                                                <th className="product-name">Product</th>
                                                <th className="product-price">Price</th>
                                                <th className="product-quantity">Quantity</th>
                                                <th className="product-subtotal">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                Object.keys(cartData).map((item, itemkey) => {
                                                    return (
                                                        <tr className="cart_item">
                                                            <td className="product-remove">
                                                                <Popconfirm
                                                                    title="Do you  want to Delete？"
                                                                    onConfirm={(e)=>deleteCartItem(item)}

                                                                >
                                                                    <Link to="" ><DeleteOutlined  style={{ fontSize:20, color: "black" }} /></Link>
                                                                </Popconfirm>
                                                            </td>

                                                            <td className="product-thumbnail cart-image">
                                                                {cartObj.getProductImage(cartData[item])}
                                                               

                                                            </td>

                                                            <td className="product-name">
                                                                {productObj.getProductName(cartData[item].product, true)}
                                                                {cartObj.getProductVariant(cartData[item])}
                                                            </td>

                                                            <td className="product-price">
                                                                <span className="amount">{productObj.PriceDisplay(cartData[item].product)}</span>
                                                            </td>

                                                            <td className="product-quantity">
                                                                <div className="quantity buttons_added">
                                                                    <InputNumber min={1} className="input-text qty text" defaultValue={cartData[item].cartQty} onChange={(e) => onchangeHandel(e, item)} />
                                                                </div>
                                                            </td>

                                                            <td className="product-subtotal">
                                                                <span className="amount">{cartObj.cartItemTotalDisplay(cartData[item])}</span>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            {/*<tr>
                                            <td className="actions" colspan="6">
                                                <div className="coupon">
                                                    <label for="coupon_code">Coupon:</label>
                                                    <input type="text" placeholder="Coupon code" value="" id="coupon_code" className="input-text" name="coupon_code" />
                                                    <input type="submit" value="Apply Coupon" name="apply_coupon" className="button" />
                                                </div>
                                                
                                                
                                            </td>
                                        </tr>*/}
                                        </tbody>
                                    </table>
                                </form>


                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">

                        <Summary></Summary>


                    </div>
                </div>}
            </div>
        </div>
        <Footer></Footer>
    </>)

}

export default CartPage;